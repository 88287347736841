<template lang="pug">
  .replace-image__folder(
    :class="{ 'is-hovered': isHovered }"
    @click="$emit('click', id)"
  )
    Drop(
      v-if="!isTeamUpload"
      @drop="emitData"
      @dragover="isDropping"
      @dragleave="stopDropping"
    )
      .icon-preview
        i.icon.icon-files-menu
      span.folder__name {{ name }}

    .icon-preview(v-else)
      i.icon.icon-files-menu
    span.folder__name {{ name }}
</template>

<script>
import { Drop } from 'vue-drag-drop';

export default {
  name: 'ReplaceImageFolder',
  components: {
    Drop,
  },
  data() {
    return {
      isHovered: false,
    };
  },
  props: {
    id: {
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    isTeamUpload: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitData(data) {
      this.$emit('drop', {
        folderId: this.id,
        fileId: data.id,
      });
    },
    isDropping() {
      this.isHovered = true;
      this.$emit('is-dropping');
    },
    stopDropping() {
      this.isHovered = false;
      this.$emit('stop-dropping');
    },
  },
};
</script>

<style lang="scss" scoped>
.replace-image__folder {
  width: calc(100% / 8);
  position: relative;
  display: inline-block;
  cursor: pointer;
  user-select: none;

  @include mobile() {
    width: calc(100% / 3);
  };

  &::before {
    content: '';
    position: relative;
    padding-top: 100%;
    display: block;
  }

  &.is-hovered {
    .icon-preview {
      background: var(--blue700-green700);
      color: $light;
    }
  }

  .icon-preview {
    background: var(--lightgrey-darkgrey600);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 80px;
    position: absolute;
    top: 12px;
    left: 12px;
    width: calc(100% - 24px);
    height: calc(100% - 24px);
    border-radius: $componentBorderRadius;
    box-shadow: 0 3px 4px rgba(10, 31, 68, 0.12), 0 0 1px rgba(10, 31, 68, 0.1);
    transition: box-shadow 0.25s ease-in-out,  background 0.25s ease-in-out, color 0.25s ease-in-out;

    @include mobile() {
      font-size: 48px;
    };

    .mobile-project & {
      background: $darkGrey800;
      color: $darkGrey600;
    }

    .icon {
      display: inline-block;
      margin-top: -30px;
      color: var(--darkgrey800-white)
    }
  }

  &.is-moving-here {
    .icon-preview {
      border: 1px solid var(--blue700-green700);
    }

    .folder__name {
      border-color: var(--blue700-green700);
    }
  }

  &:not(.is-moving-here):hover .icon-preview {
    box-shadow: 0 26px 26px rgba(10, 31, 68, 0.12), 0 0 1px rgba(10, 31, 68, 0.1);
  }

  .folder__name {
    background: var(--white-darkgrey800);
    color: var(--darkgrey800-white);
    border: 1px solid var(--white-darkgrey800);
    padding: 7px;
    font-size: 0.75em;
    position: absolute;
    left: 12px;
    bottom: 12px;
    width: calc(100% - 24px);
    border-radius: 0 0 $componentBorderRadius $componentBorderRadius;
    border-top: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .mobile-project & {
      background: $darkGrey600;
      color: $light;
      border-color: $darkGrey600;
    }
  }
}
</style>
