<template lang="pug">
.search-container.replace-images-search-url
  .form-group.form-group-element-search
    .row
      .tab-search__top
        .col-xs-auto.tab-search
            input.form-input.form-search-box(
              autocomplete="off"
              :title="searchTerm"
              v-model="searchTerm"
              type="text"
              placeholder="Paste your URL here"
              @keyup.enter="search"
            )
            button.btn-clear-keyword(
              type="button"
              :class="{ 'is-show': searchTerm.length > 0 }"
              @click="clearSearch"
            )
              i.icon.icon-cancel
      .tab-search__bottom
        BaseSlider(
          :min="10"
          :max="2000"
          :step="10"
          title="Minimum Image Width/Height:"
          :value="minLongestEdge"
          suffix="px"
          @update="updateMinLongestEdge"
        )
        BaseButton.btn-download-images(
          :is-canvas="true"
          :is-primary="true"
          @click="search"
        ) Download Images
</template>

<script>
import { isEmptyObject } from '@/assets/scripts/utilities';

export default {
  name: 'ReplaceImagesSearchURL',
  data() {
    return {
      searchTerm: '',
      minLongestEdge: 400,
    };
  },
  props: {
    currentTab: { type: String, default: 'user-files' },
    keyword: { type: Object, default: () => {} },
  },
  methods: {
    clearSearch() {
      this.searchTerm = '';
      this.$emit('clear-search');
    },
    search() {
      this.$emit('search', { url: this.searchTerm, min_longest_edge: this.minLongestEdge });
    },
    updateMinLongestEdge(val) {
      this.minLongestEdge = val;
    },
  },
  mounted() {
    if (!isEmptyObject(this.keyword)) {
      this.searchTerm = this.keyword.url;
      this.minLongestEdge = this.keyword.min_longest_edge;
    }
  },
  watch: {
    currentTab(val) {
      if (val !== 'url') this.searchTerm = '';
    },
  },
};
</script>

<style scoped lang="scss">
.search-container {
  height: 45px;
  margin: 0;
  padding: 10px 0;
  top: 0;
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  width: 100%;
  z-index: 3;

  @include mobile() {
    padding: 0 10px 10px;
  }

  .form-group {
    height: auto !important;
  }

  .tab-search__top {
    width: 100%;

    .tab-search {
      display: flex;
      justify-content: flex-start !important;
      align-items: center;
      height: 30px !important;
      position: relative;
      background: var(--lightgrey400-darkgrey600);
      border-radius: 4px;

      &.hide-dropdown {
        .search-dropdown-menu {
          display: none;
        }
      }

      .form-search-box {
        width: calc(100% - 40px);
        color: var(--darkgrey900-white);
        flex: none !important;

        &::placeholder {
          color: var(--darkgrey400-lightgrey500);
        }

        @include mobile() {
          font-size: 1rem;
        }
      }

      &.is-category-search {
        &:before {
          content: 'Category:';
          background: $blue;
          color: #fff;
          padding: 2px 5px;
          border-radius: 5px;
          font-size: 12px;
          position: absolute;
          left: 5px;
        }

        .form-search-box {
          padding-left: 75px;
          margin-right: 10px;
        }
      }
    }
  }

  .tab-search__bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 20px 0;

    @include mobile() {
      flex-direction: column;
    }

    .slider {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 0;
      margin-right: 20px;
      text-align: left;

      @include mobile() {
        flex-direction: column;
      }

      .slider__title {
        text-align: left;
        flex-shrink: 0;
        margin-right: 10px;

        @include mobile() {
          width: 100%;
        }
      }

      .slider__container {
        @include mobile() {
          margin-bottom: 10px;
        }

        .slider__value {
          @include mobile() {
            position: inherit;
            top: -14px;
          }
        }
      }
    }

    .btn-download-images {
      flex-shrink: 0;
      color: var(--blue700-white);
      background-color: var(--white-darkgrey700) !important;
      border: 1px solid var(--blue700-white);

      &:hover {
        color: $light;
        background-color: var(--blue700-black800) !important;
        border: 1px solid var(--blue700-black800) !important;
      }
    }
  }

  .btn-choose-category {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 10px;
    border-color: #fff;
    font-size: 0.75em;

    &.is-active,
    &:hover {
      border-color: $blue;
    }
  }

  .form-input {
    border: 0;
    border-radius: 4px;
    font-size: 0.875em;
    background-color: transparent;
    color: $darkGrey;
    padding-left: 10px;
    outline: none;

    &::-ms-clear {
      display: none;
    }
  }

  select.form-input {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAICAYAAAArzdW1AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAClSURBVHgBfU4xCsJAENw94+FbbFLZ6yO00EZbbeIP9AUaVLCMoCCoGH9gqV1Ky3tCKhE8d80FLkVIMsXu7Oyws9gbTDxmdqEExOLmEGKEAIsy04/0XJz36zswBEWGJGEWHrdKmOEr69OkxTmH0m/pG1oz5RU9Pk231UDAdvYLonc9rZ6GCytqR/rIoOyVy2Gzs7vMFAbLmASO0niiDlSh2x8P89ofCU451UdA2WoAAAAASUVORK5CYII=');
  }

  .btn-clear-keyword {
    position: absolute;
    right: 9px;
    top: 7px;
    display: flex;
    cursor: pointer;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    color: var(--darkgrey900-white);
    background: transparent;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s ease-in-out, color 0.25s ease-in-out;

    &:hover {
      color: var(--blue700-green700);
    }

    &.is-show {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .search-dropdown-menu {
    width: 120px;
    font-size: 0.875em;
    border-left: 1px solid $lightBg;
    // need to keep cause the search is using this
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: 0;
    width: 0;
    height: 0;

    &:focus {
      border-color: $lightBg;
    }
  }

  .btn-sidebar-search {
    background: transparent;
    color: $darkGrey;
    padding: 5px;
    font-size: 18px;
    border-radius: 0;
    height: 30px;
    transition: color 0.25s ease-in-out;

    &:hover {
      color: $blue;
      background-color: transparent;
    }
  }
}
</style>

<style lang="scss">
.empty-list.is-url {
  .search-container {
    @include mobile() {
      padding: 24px 10px 10px;
    }
  }
}

.replace-images-search-url {
  .tab-search__bottom {
    .slider__title {
      color: var(--darkgrey800-lightgrey500);
    }

    .slider__container {
      .range-wrapper {
        max-width: calc(100% - 80px);
      }

      .range-before {
        background: var(--darkgrey400-lightgrey400);

        &::after {
          background: var(--lightgrey400-darkgrey500);
        }
      }

      .slider__value {
        color: var(--darkgrey800-lightgrey500);
        width: 70px;
      }

      input[type='range']::-webkit-slider-thumb {
        background: var(--darkgrey500-white) !important;
        width: 14px !important;
        height: 14px !important;
        margin-top: -1px;
      }
    }
  }
}
</style>
