<template lang="pug">
.search-container
  .form-group.form-group-element-search
    .row
      .col-xs-auto.tab-search
        input.form-input.form-search-box(
          autocomplete="off"
          :title="searchTerm"
          v-model="searchTerm"
          type="text"
          placeholder="Search"
          @keyup.enter="search"
        )
        button.btn-clear-keyword(
          type="button"
          :class="{ 'is-show': searchTerm.length > 0 }"
          @click="clearSearch"
        )
          i.icon.icon-cancel
        button#btn-media-search.btn.btn-sidebar-search(
          type="button"
          @click="search"
        )
          i.icon.icon-search
</template>

<script>
export default {
  name: 'ReplaceImagesSearch',
  data() {
    return {
      searchTerm: '',
    };
  },
  props: {
    currentTab: { type: String },
  },
  methods: {
    clearSearch() {
      this.searchTerm = '';
      this.$emit('clear-search');
    },
    search() {
      console.log('search');
      this.$emit('search', this.searchTerm);
    },
  },
  watch: {
    currentTab(val) {
      if (val !== 'stocks') this.searchTerm = '';
    },
  },
};
</script>

<style lang="scss">
.search-container {
  height: 45px;
  margin: 0;
  padding: 10px 0;
  top: 0;
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  width: 100%;
  z-index: 3;

  .tab-search {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    position: relative;
    background: $borderGrey;
    border-radius: 4px;

    &.hide-dropdown {
      .search-dropdown-menu {
        display: none;
      }
    }

    .form-search-box{
      width: 100%;

      @include mobile() {
        font-size: 1rem;
      };
    }

    &.is-category-search{
      &:before {
        content: "Category:";
        background: $blue;
        color: #fff;
        padding: 2px 5px;
        border-radius: 5px;
        font-size: 12px;
        position: absolute;
        left: 5px;
      }

      .form-search-box{
        padding-left: 75px;
        margin-right: 10px;
      }
    }
  }

  .btn-choose-category {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 10px;
    border-color: #fff;
    font-size: 0.75em;

    &.is-active,
    &:hover {
      border-color: $blue;
    }
  }

  .form-input {
    flex: 1 auto;
    border: 0;
    border-radius: 4px;
    font-size: 0.875em;
    background-color: transparent;
    color: $darkGrey;
    padding-left: 10px;
    outline: none;

    &::-ms-clear {
      display: none;
    }
  }

  .form-input::placeholder {
    color: $darkGrey;
  }

  select.form-input {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAICAYAAAArzdW1AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAClSURBVHgBfU4xCsJAENw94+FbbFLZ6yO00EZbbeIP9AUaVLCMoCCoGH9gqV1Ky3tCKhE8d80FLkVIMsXu7Oyws9gbTDxmdqEExOLmEGKEAIsy04/0XJz36zswBEWGJGEWHrdKmOEr69OkxTmH0m/pG1oz5RU9Pk231UDAdvYLonc9rZ6GCytqR/rIoOyVy2Gzs7vMFAbLmASO0niiDlSh2x8P89ofCU451UdA2WoAAAAASUVORK5CYII=');
  }

  .btn-clear-keyword {
    position: absolute;
    right: 28px;
    top: 9px;
    display: flex;
    cursor: pointer;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    color: $darkGrey;
    background: transparent;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s ease-in-out, color 0.25s ease-in-out;

    &:hover {
      color: $blue;
    }

    &.is-show {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .search-dropdown-menu {
    width: 120px;
    font-size: 0.875em;
    border-left: 1px solid $lightBg;
    // need to keep cause the search is using this
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: 0;
    width: 0;
    height: 0;

    &:focus {
      border-color: $lightBg;
    }
  }

  .btn-sidebar-search {
    background: transparent;
    color: $darkGrey;
    padding: 5px;
    font-size: 18px;
    border-radius: 0;
    height: 30px;
    transition: color 0.25s ease-in-out;

    &:hover {
      color: $blue;
      background-color: transparent;
    }
  }
}
</style>
