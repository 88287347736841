<template lang="pug">
.replace-logo--overlay
  CanvasModal.smart-create-logo__modal(
    :class="{ 'is-dark': isDarkMode }"
    :title="modalTitle"
    :isShow="isShow"
    @closeOverlay="closeOverlay"
    :extraClass="extraClass"
    :showMainAreaOverlay="showMainAreaOverlay"
    @closeMainAreaOverlay="showMainAreaOverlay = false"
  )
    template(#top)
      span Choose a logo or upload a new one.
      BaseButton.use-logo__button(
        :is-canvas="true"
        :is-primary="true"
        :is-tall="true"
        :is-disabled="!tmpSelectedImageId"
        @click="confirmImage"
      ) Use this logo
    .logo-bar__mobile(
      v-if="images.length"
    )
      template(v-if="isMultiSelecting")
        button.btn-delete-file(
          v-if="multiSelectedFiles.length"
          type="button"
          @click="deleteFile"
        ) Delete
        button.btn-cancel-multiselect(
          type="button"
          @click="cancelEvent"
        ) Cancel
      button.btn-select-files(
        v-if="!isMultiSelecting"
        type="button"
        @click="isMultiSelecting = true"
      ) Select
    .replace-logo__container(
      @dragenter="dragEnter"
      @dragexit="dragLeave"
    )
      //--------------
      //- for dragging files from outside of app
      //--------------
      .drop-zone-container
        DropZone(
          v-if="isDragEnter"
          @dropZoneDrop="drop"
          @dropZoneDragLeave="dragLeave"
        )
      BaseDropdown.team-brand-switcher(
        v-if="isInTeamWorkspace && !hasNoTeamBrandInTeamWorkspace"
        :options="teamBrandDropdownOptions"
        :value="getActiveTeamBrandId"
        @change="switchTeamBrand"
      )
      template(v-if="images.length && !showPreloader")
        ReplaceImage(
          v-for="image in images"
          :key="`replace-logo-${image.id}`"
          :id="image.id"
          :url="getThumbUrl(image)"
          @click="selectImage"
          :class="{'is-selected': image.id === tmpSelectedImageId}"
          :isSelected="multiSelectedFiles.indexOf(image.id) > -1"
          :isSelectable="isMultiSelecting"
          :is-smart-create="true"
          :is-team-brand-logo="isInTeamWorkspace"
          @select="addMultiSelectedFiles"
          @deselect="removeMultiSelectedFiles"
        )
      label.btn-upload-logo(
        v-if="isShowFileInput"
        :class="{ 'full-width': !images.length, 'btn--unclickable': isTeamMember, 'is-dark': isDarkMode }"
        @drop.prevent="onDropFileInput"
        @dragover.prevent
        @click="onClickFileInput"
      )
        input(
          type="file"
          id="manual-file-upload"
          ref="fileUpload"
          :accept="mobileFileAccept"
          @change="uploadLogo"
        )
        .btn__content
          i.icon.icon-plus
          p.title {{ addLogoTitle }}
          p.text {{ addLogoText }}
      BasePreloader(
        v-if="showPreloader"
        :type=2
      )

    //---------
    //- UPLOADING FILES IN MOBILE
    //---------
    transition(
      name="slide-mobile-upload"
    )
      .upload-files__mobile(
        v-if="showFileUploadOption && !isTeamMember"
        v-click-outside="onClickOutsideFileUploadOptions"
      )
        span.upload-files__name Upload
        .btn-upload-image-phone
          input.upload-files__input(
            type="file"
            id="mobile-file-upload"
            ref="mobileFileUpload"
            :accept="mobileFileAccept"
            @change="uploadLogo"
          )
          span.text Upload image from phone
          i.icon.icon-plus
        DropboxSelector.btn-upload-image-dropbox(
          :isReplacingLogo="true"
          @show-preloader="showPreloader = true"
          @hide-preloader="showPreloader = false; mobileHideFileUpload"
          @image-uploaded="dropboxUploadSuccess()"
        )
    button.btn-upload-mobile(
      type="button"
      @click="mobileShowFileUpload"
    )
      i.icon.icon-plus
  UploadingModal(
    v-if="showUploadingModal"
    :isDone="isDoneUploading"
    :isSaving="isSaving"
    :isProcessing="isProcessing"
    :text="uploadingText"
    @confirm="confirmUploadingOverlay"
    @pause-upload="doPauseChunkUpload"
    @resume-upload="doStartChunkUpload"
    @cancel-upload="cancelUpload"
  )
  BaseDialog.file-validation-modal(
    v-if="isFileError"
    isShow=true
    isSuccess=true
    :text="fileErrorMessage"
    noCancel=true
    button1Text="Close"
    :noUpgrade="!showUpgradeButton"
    @confirm="isFileError = false"
    @closeOverlay="isFileError = false"
  )
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'; // eslint-disable-line
import vClickOutside from 'v-click-outside';
import Api from '@/services/api/Api';

import BasePreloader from '../../UI/BasePreloader.vue';
import DropboxSelector from '../../ProjectCanvas/AssetsSidebar/DropboxSelector.vue';
import ReplaceImage from './children/ReplaceImage.vue';
import ReplaceImageFolder from './children/ReplaceImageFolder.vue';
import ReplaceImagesOverlayTab from './children/ReplaceImagesOverlayTab.vue';
import ReplaceImagesSearch from './children/ReplaceImagesSearch.vue';
import UploadingModal from '../../ProjectCanvas/PageOverlay/UploadingModal.vue';
import DropZone from '@/components/SmartCreate/PageOverlay/children/DropZone.vue';

import { isMobile } from '@/assets/scripts/utilities';
import { allowNonLoginUserUpload } from '@/assets/scripts/variables';

import CollectionsApi from '@/services/api/CollectionsApi';
import BrandApi from '@/services/api/BrandApi';
import TeamApi from '@/services/api/TeamApi';

import uploadingMixin from '../../mixins/uploading-mixins';
import helperMixins from '@/components/mixins/helper-mixins';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

export default {
  name: 'ReplaceLogoOverlay',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  mixins: [uploadingMixin, helperMixins, darkModeMixin],
  components: {
    DropZone,
    BasePreloader,
    DropboxSelector,
    ReplaceImage,
    ReplaceImageFolder,
    ReplaceImagesOverlayTab,
    ReplaceImagesSearch,
    UploadingModal,
  },
  data() {
    return {
      images: [],
      tmpSelectedImageId: '',
      isDragEnter: false,
      showPreloader: true,
      showFileUploadOption: false,
      isMobile: isMobile(),
      showMainAreaOverlay: false,
      isExiting: false,
      multiSelectedFiles: [],
      isMultiSelecting: false,
      allowNonLoginUserUpload: allowNonLoginUserUpload(),
    };
  },
  computed: {
    ...mapState(['isLoggedIn']),
    ...mapState('team', ['teamId', 'teamSlug']),
    ...mapGetters('team', [
      'isInTeamWorkspace',
      'getActiveTeamBrandId',
      'getTeamBrands',
      'isTeamMember',
      'hasTeamBrands',
    ]),
    modalTitle() {
      const fallback = this.isInTeamWorkspace ? 'Team Brand' : 'My Brand';
      return this.title || fallback;
    },
    extraClass() {
      const cssClass = ['canvas-modal--design'];

      if (this.isMobile) cssClass.push('is-slide-up');
      if (this.isExiting) cssClass.push('is-exiting');

      return cssClass.join(' ');
    },
    addLogoTitle() {
      let title = 'Add new logo';

      if (this.isMobile && !this.images.length) title = 'Upload logo';
      else if (this.hasNoTeamBrandInTeamWorkspace) title = 'Your team does not have any Team Brand yet';
      else if (this.hasNoImagesInTeamBrand) title = 'You don’t have any logo setup in this Team Brand';
      else if (!this.images.length) title = 'You don’t have any logo setup in My Brand';

      return title;
    },
    addLogoText() {
      let text = 'Supports only JPEG, JPG and PNG format';

      if (this.isMobile && !this.images.length) text = 'Tap here to upload files here. You can add JPEG, JPG and PNG.';
      else if (this.hasNoTeamBrandInTeamWorkspace && !this.isTeamMember) text = 'Stay consistent with all your designs with your brand logo, color and fonts. Tap here to Setup Brand.'; // eslint-disable-line
      else if (this.isTeamMember && !this.images.length) text = 'Only Team Owner and Team Designers are able to upload logos.'; // as member cannot do anything
      else if (!this.images.length) text = 'Click to upload your logo. Supports JPEG, JPG and PNG only';

      return text;
    },
    hasNoImagesInTeamBrand() {
      return this.isInTeamWorkspace && !this.images.length;
    },
    hasNoTeamBrandInTeamWorkspace() {
      return this.isInTeamWorkspace && !this.hasTeamBrands;
    },
    teamBrandDropdownOptions() {
      let options = [];

      if (!this.hasNoTeamBrandInTeamWorkspace) {
        options = this.getTeamBrands.map((brand) => { // eslint-disable-line
          return {
            value: brand.id,
            name: brand.brand_name,
          };
        });
      }

      return options;
    },
    isShowFileInput() {
      if (!this.images.length) return !this.showPreloader;
      return !this.showPreloader && !this.isTeamMember;
    },
  },
  methods: {
    ...mapMutations([
      'setIsModalVisible',
    ]),
    ...mapMutations('assetsSidebar', [
      'setShutterstockSearchId',
    ]),
    ...mapMutations('canvasElements', ['setProjectBrandId']),
    ...mapActions('assetsSidebar', [
      'promptConfirmDeleteUserFile',
    ]),
    ...mapActions('team', ['addTeamBrandLogo']),
    closeOverlay() {
      if (this.isMobile) {
        this.isExiting = true;
        setTimeout(() => {
          this.setIsModalVisible(false);
          this.$emit('closeOverlay');
        }, 300);
      } else {
        this.setIsModalVisible(false);
        this.$emit('closeOverlay');
      }
    },
    getImages() {
      console.log('getImages');
      const endpoint = 'user-files';
      const params = {
        menu: 'logos',
        search: '',
      };
      this.showPreloader = true;
      this.images = [];

      if (!this.isLoggedIn) {
        if (!this.allowNonLoginUserUpload) {
          this.alertError('', 'Cannot get user files, login required.', 3000, 'TopCenterNotif', true);
          this.showPreloader = false;
        } else {
          Api.getTemporaryFile(this.tmpUserId)
            .then((response) => {
              const data = response.data ? response.data : response;
              const fetchedImages = data.results;

              // sometimes stock return same image twice
              for (let i = 0; i < fetchedImages.length; i += 1) {
                const newImage = fetchedImages[i];
                const isExist = this.images.findIndex(img => img.id === newImage.id) > -1;

                if (!isExist) {
                  this.images.push(newImage);
                }
              }

              this.showPreloader = false;
            })
            .catch((error) => {
              console.error('error', error);
              this.showPreloader = false;
            });
        }
      } else if (this.isInTeamWorkspace) {
        if (this.hasTeamBrands) {
          const brandId = this.getActiveTeamBrandId;

          TeamApi.getTeamLogos(this.teamId, brandId)
            .then((response) => {
              const { results } = response.data;
              this.images = results;

              // check for extra team logo
              TeamApi.getTeamBrandSetupDetails({
                teamId: this.teamId,
                brandId,
              })
                .then((res) => {
                  const extraTeamLogoResults = res.data.results;

                  if (extraTeamLogoResults.is_show_logo_on_team_brand) {
                    this.images.unshift({
                      id: 'brand_setup',
                      file: extraTeamLogoResults.initial_logo.meta_value,
                      preview_url: extraTeamLogoResults.initial_logo.meta_value,
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                })
                .finally(() => {
                  this.showPreloader = false;
                });
            })
            .catch((error) => {
              console.log(error);
              this.showPreloader = false;
            });
        } else {
          this.showPreloader = false;
        }
      } else {
        CollectionsApi.getItems(endpoint, params, 'v2')
          .then((response) => {
            const data = response.data ? response.data : response;
            const fetchedImages = data.results;

            // has search_id
            if (typeof data.search_id !== 'undefined') {
              // update store for license usage
              this.setShutterstockSearchId(data.search_id);
            }

            // sometimes stock return same image twice
            for (let i = 0; i < fetchedImages.length; i += 1) {
              const newImage = fetchedImages[i];
              const isExist = this.images.findIndex(img => img.id === newImage.id) > -1;

              if (!isExist) {
                this.images.push(newImage);
              }
            }
            this.showPreloader = false;
          })
          .catch((error) => {
            console.error('error', error);
            this.showPreloader = false;
          });
      }
    },
    uploadLogo(event) {
      if (!event || !event.target || !event.target.files) return;
      this.doUploadLogo(event.target.files[0]);
    },
    doUploadLogo(file) {
      this.showPreloader = true;

      if (this.isInTeamWorkspace) {
        const brandId = this.getActiveTeamBrandId;

        TeamApi.addTeamLogo(this.teamId, brandId, file)
          .then((response) => {
            const userFiles = response.data.results.user_files;
            this.images.push(userFiles);
            this.alertUpload('success', 'Image Uploaded', 7000);
          })
          .catch((error) => {
            console.error('error', error);
          })
          .finally(() => {
            this.showPreloader = false;
          });
      } else {
        BrandApi.addBrandLogo(file)
          .then((response) => {
            const userFiles = response.data.results.user_files;
            this.images.push(userFiles);
            this.alertUpload('success', 'Image Uploaded', 7000);
          })
          .catch((error) => {
            console.error('error', error);
          })
          .finally(() => {
            this.showPreloader = false;
          });
      }
    },
    getUrl(image) {
      return image.file;
    },
    getThumbUrl(image) {
      return image.preview_url;
    },
    selectImage(id) {
      this.tmpSelectedImageId = id;
    },
    confirmImage() {
      const index = this.images.findIndex(image => image.id === this.tmpSelectedImageId);
      const selectedImage = this.images[index];
      this.setIsModalVisible(false);
      this.$emit('select', this.getUrl(selectedImage));
    },
    dragEnter() {
      if (this.hasNoTeamBrandInTeamWorkspace || this.isTeamMember) return;

      this.isDragEnter = true;
    },
    dragLeave() {
      this.isDragEnter = false;
    },
    drop(file) {
      this.files = [file];
      this.isDragEnter = false;
      this.$nextTick(() => {
        this.doUploadLogo(file);
      });
    },
    onDropFileInput(event) {
      if (this.hasNoTeamBrandInTeamWorkspace || this.isTeamMember) return;
      if (!event.dataTransfer || !event.dataTransfer.files || !event.dataTransfer.files.length) return;

      const file = event.dataTransfer.files[0];
      this.doUploadLogo(file);
    },
    onClickFileInput() {
      if (!this.hasNoTeamBrandInTeamWorkspace) return;

      window.location.href = `${process.env.VUE_APP_DASHBOARD_URL}/team/${this.teamSlug}/team-brands`;
    },
    removeFileId(id) {
      // need to use == because it is comparing string and integer
      // eslint-disable-next-line
      const index = this.images.findIndex(image => image.id == id);
      if (index >= 0) this.images.splice(index, 1);
    },
    onClickOutsideFileUploadOptions() {
      if (this.showFileUploadOption) {
        this.mobileHideFileUpload();
      }
    },
    mobileHideFileUpload() {
      this.showMainAreaOverlay = false;
      this.showFileUploadOption = false;
    },
    mobileShowFileUpload() {
      this.showMainAreaOverlay = true;
      this.showFileUploadOption = true;
    },
    dropboxUploadSuccess() {
      this.getImages();
      this.mobileHideFileUpload();
    },
    addMultiSelectedFiles(id) {
      const index = this.multiSelectedFiles.indexOf(id);
      if (index === -1) this.multiSelectedFiles.push(id);
    },
    removeMultiSelectedFiles(id) {
      const index = this.multiSelectedFiles.indexOf(id);
      if (index > -1) this.multiSelectedFiles.splice(index, 1);
    },
    deleteFile() {
      this.promptConfirmDeleteUserFile({
        itemId: this.multiSelectedFiles.join('|'),
        isFolder: false,
        isTemporaryFile: !this.isLoggedIn,
      });
      this.multiSelectedFiles.length = 0;
    },
    cancelEvent() {
      this.isMultiSelecting = false;
      this.multiSelectedFiles.length = 0;
      this.multiSelectedFiles.pop(); // to trigger computed
    },
    switchTeamBrand(id) {
      this.setProjectBrandId(id);
      this.getImages();
    },
  },
  beforeMount() {
    this.getImages();
  },
  watch: {
    getTeamBrands: {
      handler() {
        // handle if sometimes API slow on getting team brand
        if (!this.images.length) this.getImages();
      },
      deep: true,
    },
  },
  mounted() {
    this.isImageLogoOverlay = true;

    this.$root.$on('delete-user-file', (id) => {
      this.removeFileId(id);
      this.tmpSelectedImageId = '';
    });
  },
};
</script>

<style lang="scss">
.replace-logo--overlay {
  .canvas-modal__main {
    max-height: 780px;

    .title {
      font-size: 1.5em;
      font-weight: 600;
    }
    .content {
      padding: 0;
      display: block;
    }

    p {
      margin: 0.25em 0;
      text-align: center;
    }

    .text {
      margin-bottom: 0;
    }
  }

  .canvas-modal__content {
    @include mobile() {
      bottom: 0;
      margin-top: auto;
    };
  }

  .smart-create & .canvas-modal__main .content--top {
    @include mobile() {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 30px;
      box-shadow: 0px -10px 20px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);

      & > span {
        display: none;
      }

      .btn {
        width: 100%;
      }
    }
  }

  .btn-upload-logo {
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    input[type="file"] {
      position: absolute;
      visibility: hidden;
    }

    .btn__content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    &:not(.full-width) {
      width: calc(100% / 6);
      padding: 10px;
      margin-top: -10px;
      display: inline-block;

      @include mobile() {
        display: none;
      };

      &::before {
        content: '';
        position: relative;
        padding-top: 100%;
        display: block;
      }

      .btn__content {
        position: absolute;
        left: 10px;
        top: 10px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        border-radius: $componentBorderRadius;
        border: 1px solid $defaultBorder;
        box-shadow: 0 3px 4px rgba(10, 31, 68, 0.12), 0 0 1px rgba(10, 31, 68, 0.1);
        transition: border 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
        padding: 5px;
      }

      &:hover {
        .btn__content {
          border-color: var(--blue700-green700);
          box-shadow: 0 26px 26px rgba(10, 31, 68, 0.12), 0 0 1px rgba(10, 31, 68, 0.1);
        }

        .icon {
          color: var(--blue700-green700);
        }
      }

      .icon {
        font-size: 2em;
        color: var(--darkgrey800-white);
        transition: border 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
      }

      .title {
        font-size: 0.875em !important;
        margin-bottom: 0;
        color: var(--darkgrey800-lightgrey400);
      }

      .text {
        color: var(--grey3-lightgrey800);
        font-size: 0.75em !important;
      }
    }

    &.full-width {
      border: 2px dashed var(--lightgrey400-darkgrey100);
      height: 300px;
      width: 620px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: $componentBorderRadius;
      transition: background 0.25s ease-in-out;

      &.is-dark {
        &:hover {
          box-shadow: 7px 9px 7px 0 rgb(0 0 0 / 15%);
        }
      }

      @include mobile() {
        width: calc(100% - 40px);
        height: 350px;
        top: 20px;
        left: 20px;
        transform: none;
        padding: 20px;
      };

      &:hover {
        background: var(--lightgrey-lightgrey6);
      }

      .icon {
        font-size: 4em;
        margin-top: -20px;
        color: var(--darkgrey500-lightgrey300);
      }

      p {
        color: var(--black-white);
      }

      .title {
        font-size: 1.25em !important;
        margin-bottom: 5px;
        color: var(--black-white);
      }
    }

    &.btn--unclickable {
      pointer-events: none;
    }
  }

  .content {
    height: 90vh;
    overflow: hidden;
  }

  .canvas-modal {
    text-align: left;
  }

  .is-selected {
    img {
      border: 2px solid var(--blue700-green700);
    }
  }

  .preloader {
    height: 128px;
  }

  .replace-logo__container {
    width: 100%;
    height: 100%;
    padding: 30px 20px;

    @include desktop() {
      overflow-y: auto;
      overflow-x: hidden;
    };

    @include mobile() {
      padding: 10px 10px 75px;
    };

    .drop-zone-container {
      pointer-events: none;
      z-index: 2;
      cursor: pointer;
      top: 58px;
      left: 0;
      width: 100%;
      transform: none;
      height: calc(100% - 58px);

      > div {
        pointer-events: auto;
      }
    }

    .team-brand-switcher {
      margin: 0 12px;

      .dropdown__selected {
        color: var(--darkGrey800-lightgrey300);
        border-color: var(--blue700-green700);
        background: transparent;
      }

      .dropdown__list {
        position: absolute;
        z-index: 1;
        width: 100%;
        background: var(--white-darkgrey900);
      }

      .dropdown__item {
        color: var(--darkGrey800-lightgrey300);
        border-bottom: 1px solid var(--lightgrey300-darkgrey7);
        background: transparent !important;

        &.is-selected{
          &::after {
            content: $icon-checked;
            font-family: 'icomoon';
            position: absolute;
            right: 15px;
            top: 9px;
            font-size: 1rem;
            color: $green700;
            font-weight: normal;
          }
        }

        &:hover {
          background: var(--lightgrey300-darkgrey800) !important;
          color: var(--darkgrey800-lightgrey900);
          transition: color .2s ease-in-out;
        }
      }
    }
  }

  .btn-upload-mobile {
    background: $blue;
    color: $light;
    width: 54px;
    height: 54px;
    display: none;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 13px 13px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
    right: 15px;
    bottom: 118px;
    position: fixed;
    z-index: 10;
    font-size: 1.25em;
    border-radius: 50%;

    @include mobile() {
      display: flex;
    };
  }


  .upload-files__mobile {
    position: absolute;
    bottom: 15px;
    left: 15px;
    width: calc(100% - 30px);
    background: #fff;
    border-radius: $componentBorderRadius;
    box-shadow: 0px 13px 13px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
    z-index: 11;
    padding: 10px;
    text-align: left;

    button {
      border-bottom: 1px solid $borderGrey;

      &:last-child {
        border-bottom: 0;
      }
    }

    .text {
      font-size: 0.875em;
    }

    .icon {
      font-size: 1.25em;
    }

    .upload-files__name {
      width: 100%;
      padding: 10px 0 12px;
      font-size: 1.125em;
      font-weight: 600;
      display: block;
      color: $inputLabel;
    }

    .btn-upload-image-phone,
    .btn-upload-image-dropbox {
      width: 100%;
      padding: 12px 0;
      height: auto;
    }

    .btn-upload-image-phone,
    .btn-upload-image-dropbox .select-files {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .btn-upload-image-phone {
      border-bottom: 1px solid $borderGrey;
      position: relative;
    }

    #mobile-file-upload {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      height: 100%;
      width: 100%;
    }

    .btn-upload-image-dropbox {
      img {
        width: 20px;
      }
    }
  }
}

.smart-create-logo__modal {
  .content--top {
    background: var(--lightgrey100-black700);
    color: var(--darkgrey800-lightgrey400);

    .use-logo__button {
      &:disabled {
        color: var(--lightgrey700-darkgrey200);
        background: var(--lightgrey400-darkgrey600);
      }
    }
  }

  &.is-dark {
    .content--top {
      box-shadow: 0px 18px 18px rgb(0 0 0 / 12%), 0px 0px 1px rgb(0 0 0 / 10%);
    }
  }

  .canvas-modal__header {
    background: var(--blue700-darkgrey900) !important;
    color: $white;
  }

  .canvas-modal__content {
    background: var(--white-darkgrey700);

    .canvas-modal__closebtn {
      color: $white;
    }

    .content {
      background: var(--white-darkgrey700);
      min-height: 500px;
    }
  }

  * {
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    ::-webkit-scrollbar-track {
      background-color: rgba($darkGrey600, 0);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--lightgrey500-black800);
      transition: background 0.25s ease-in-out;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--lightgrey500-black800); // so styling from Project.vue for scrollbar hover not applying
    }
  }
}

.logo-bar__mobile {
  display: none;
  padding: 15px 15px 0;
  width: 100%;

  button {
    font-size: 0.875em;
    font-weight: 500;
    display: inline-block;
  }

  @include mobile() {
    display: inline-flex;
  }

  .btn-delete-file {
    color: $red;
  }

  .btn-select-files,
  .btn-cancel-multiselect {
    margin-left: auto;
  }
}

@include mobile() {
  .slide-mobile-upload-enter-to, .slide-mobile-upload-leave {
    transform: translateY(0);
  }

  .slide-mobile-upload-enter, .slide-mobile-upload-leave-to {
    transform: translateY(100%);
  }

  .slide-mobile-upload-enter-active {
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  .slide-mobile-upload-leave-active {
    transition-duration: 0.3s;
    transition-timing-function: ease-in;
  }
}
</style>
