<template lang="pug">
  .inner-liner(
    @click="$emit('close-overlay')"
  )
    i.icon.icon-upload1
    .title {{ title }}
    .text {{ text }}
</template>

<script>
export default {
  name: 'DropHere',
  props: {
    title: {
      type: String,
      default: 'Drop to upload your image here',
    },
    text: {
      type: String,
      default: 'You can add JPEG, JPG and PNG.',
    },
  },
};
</script>

<style lang="scss" scoped>
.inner-liner {
  width: 100%;
  height: 100%;
  border: 1px dashed #FFFFFF;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  color: #fff;

  .icon {
    font-size: 4em;
    margin-top: -20px;
  }

  .title {
    font-size: 1.25em !important;
    margin-bottom: 5px;
  }
}
</style>
