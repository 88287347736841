import {
  mapGetters, mapMutations, mapState,
} from 'vuex';

import cloneDeep from 'lodash.clonedeep';
import { permutableTypes } from '@/assets/scripts/enums';
import { sceneFormat, allowNonLoginUserUpload } from '@/assets/scripts/variables';
import { isMobile } from '@/assets/scripts/utilities';

import Api from '@/services/api/Api';
import ProjectApi from '@/services/api/ProjectApi';
import TemplateApi from '@/services/api/TemplateApi';

import helperMixins from '@/components/mixins/helper-mixins';

const uploadPermutationMixin = {
  mixins: [
    helperMixins,
  ],
  props: {
    maxImages: { type: Number, default: 1 },
  },
  data() {
    return {
      images: [],
      imageFormat: {
        url: '',
      },
      clickedImageIndex: -1,
      preSelectedTab: '',
      isReplacingImages: false,
      isReplacingLogo: false,
      allowNonLoginUserUpload: allowNonLoginUserUpload(),
    };
  },
  computed: {
    ...mapState([
      'showStoryboardOverlay',
      'isLoggedIn',
      'isModalVisible',
      'smartCreateTrackingId',
    ]),
    ...mapState('inspirationList', [
      'files',
      'tmpUserId',
      'heroIndex',
      'isLogo',
      'showRemoveBgOption',
      'confirmRemoveBg',
      'tmpUserFiles',
      'userFileId',
      'isTemporaryFile',
      'isTeamFileUpload',
      'tmpTeamId',
    ]),
    ...mapState('team', ['teamId']),
    ...mapState('canvasElements', [
      'permutationScenesIds',
    ]),
    ...mapGetters('canvasElements', [
      'getPermutationSceneIdBySlug',
      'getProjectId',
      'getCanvasSize',
    ]),
    ...mapState('userData', [
      'userId',
    ]),
    imageType() {
      return this.isLogo ? 'Logo' : 'Product';
    },
    getTeamIdQueryUrl() {
      return this.$route.query.team_id;
    },
    getFolderIdQueryUrl() {
      return this.$route.query.folder_id;
    },
  },
  methods: {
    ...mapMutations([
      'setShowSidebar',
      'setShowCanvasPreloader',
      'setIsModalVisible',
      'setSmartCreateTrackingId',
    ]),
    ...mapMutations('assetsSidebar', [
      'setActiveGroup',
    ]),
    ...mapMutations('canvasElements', [
      'updateActiveSceneId',
      'addSceneToArray',
      'updateCanvasSizeWithTemplate',
    ]),
    ...mapMutations('inspirationList', [
      'setFiles',
      'setHeroIndex',
      'setShowRemoveBgOption',
      'setConfirmRemoveBg',
      'setClippingImageJson',
      'setTmpUserFiles',
      'setInspirationTemplateTags',
      'setIsTemporaryFile',
      'setShowRemoveBgFailedDialog',
      'setFailedRemoveBgImageUrl',
      'setRemoveBgSuccess',
    ]),
    ...mapMutations('inspirationList', [
      'setRemoveBgItem',
    ]),
    selectImage(url) {
      this.images[0].url = url;
      this.isReplacingImages = false;

      // const tmpFiles = this.files;
      const tmpFiles = [];
      tmpFiles.push(url);
      this.setFiles(tmpFiles);

      // if previously there is no hero index
      // set it to this one
      if (this.heroIndex === -1) {
        this.setHeroIndex(this.clickedImageIndex);
      }
    },
    getImageUrlFromFile(file) {
      return (typeof file === 'object') ? URL.createObjectURL(file) : '';
    },
    setHeroImage(index) {
      this.setHeroIndex(index);
    },
    findInspiration() {
      if (this.files.length === 0) {
        // eslint-disable-next-line
        alert('Upload file');
        return;
      }
      if (this.isLogo) {
        if (!this.allowNonLoginUserUpload) {
          this.alertError('', 'Uploading cancelled, login required.', 3000, 'TopCenterNotif', true);
        }
      } else {
        this.setShowRemoveBgOption(true);
      }
    },
    doConfirmRemoveBg(item) {
      this.setConfirmRemoveBg(item);
      this.setShowRemoveBgOption(false);
      this.uploadSmartCreateFile();
    },
    uploadSmartCreateFile() {
      console.log('uploadSmartCreateFile', this.files[0]);
      console.log('upload smart create file');
      if (!this.isLoggedIn && !this.allowNonLoginUserUpload) {
        this.alertError('', 'Uploading cancelled, login required.', 3000, 'TopCenterNotif', true);
        return;
      }

      if (!this.files[0]) {
        return;
      }

      // upload temporary file to get the filtered templates
      const params = {
        // user_id: this.isLoggedIn ? this.userId : this.tmpUserId,
        // _tmp_user_id: this.tmpUserId,
        user_id: this.userId,
        provider: 'temporary_file',
        is_logo: this.isLogo,
        confirm_remove_bg: this.confirmRemoveBg,
        file_id: this.userFileId,
      };

      if (this.isTeamFileUpload) {
        params.team_id = this.teamId;
        params._tmp_team_id = this.teamId; // eslint-disable-line
      } else {
        params._tmp_user_id = this.userId; // eslint-disable-line
      }

      // REMOVE BG METHOD
      if (!this.showStoryboardOverlay) {
        // smart-create
        this.$emit('next-step');
      } else {
        // story board
        this.setShowCanvasPreloader(true);
      }

      // upload temporary file also accepts loggedin user, just need to set parameter with correct user_id and not the tmpUserId
      Api.uploadTemporaryFile(this.files, params)
        .then((response) => {
          // console.log(response);
          if (response.data.success) {
            const {
              tags, temporary_files, remove_bg_image, is_temporary_file, success,
            } = response.data.results;

            this.setInspirationTemplateTags(tags);

            // REMOVE BG METHOD
            if (this.confirmRemoveBg) {
              const tmpFile = [];
              if (typeof success !== 'undefined' && success === false) {
                // failed processing remove.bg
                // notify user and
                // use the temporary file instead
                this.setRemoveBgSuccess(false);
                tmpFile.push(temporary_files[0]);
              } else {
                // success remove bg
                this.setRemoveBgSuccess(true);
                const removeBgImage = remove_bg_image.results || remove_bg_image;
                tmpFile.push(removeBgImage);
              }
              // NOTE: uncomment the bottom for mobile smart create
              // let successfulRemoval = true;
              // const file = remove_bg_image[0];

              // if (file && file.data && file.data[0] && file.data[0].remove_bg_error) {
              //   successfulRemoval = false;
              // }

              // this.setRemoveBgSuccess(successfulRemoval);
              // tmpFile.push(file);
              this.setTmpUserFiles(tmpFile);
            } else {
              this.setTmpUserFiles(temporary_files);
            }

            this.setIsTemporaryFile(is_temporary_file || false);

            // from story board toggle add scene
            if (this.showStoryboardOverlay) {
              this.storyboardCreateScene();
            }
            // CLIPPING MAGIC METHOD
            // if (this.confirmRemoveBg) {
            //   this.setClippingImageJson(remove_bg_image);
            // }
          }
        })
        .catch((error) => {
          console.log(error);
          this.alertWarn('', 'Image Upload Failed. Please try again', 1500, 'BottomCenterNotif', true);
        });
    },
    storyboardCreateScene() {
      if (this.isLogo) {
        const additionalParams = {
          logoUrl: this.files[0],
        };
        this.addScene(permutableTypes.LOGO, additionalParams);
      } else {
        // const selectedImageObject = this.tmpUserFiles[0].data[0];
        // const heroData = {
        //   urlHd: selectedImageObject.download_link,
        //   url: selectedImageObject.w_960,
        //   thumb: selectedImageObject.thumb_link,
        // };
        const heroData = {
          temporary_file: this.tmpUserFiles[0].id, // pass the temporary file id so the admin will move the temporary file to user's file, before applying it inside the scene.
          user_id: this.isTemporaryFile ? this.tmpUserId : this.userId,
        };
        this.addScene('image', { heroData });
      }
    },
    addScene(type, additionalParams = {}) {
      // TODO: JERIC
      // need to get the id of different type of scenes
      // maybe can add params that can pass the user files
      const params = sceneFormat[0];

      if (type) {
        // comment by siska
        // the slug is hardcoded
        //  if the admin change the slug
        // not sure how to keep this updated
        let slug = permutableTypes.TEXT;

        if (type === 'image') {
          // check if it has removed bg
          if (this.confirmRemoveBg) slug = permutableTypes.PRODUCT_WO_BG;
          else slug = permutableTypes.PRODUCT_W_BG;
          params.hero_data = additionalParams.heroData;
        } else if (type === 'logo') {
          params.logo_url = additionalParams.logoUrl;
          slug = permutableTypes.LOGO;
        } else if (type === 'text-logo') {
          params.logo_url = additionalParams.logoUrl;
          slug = permutableTypes.TEXT_LOGO;
        }


        // for canvas use the passed scene Id
        let sceneId = additionalParams.sceneId || this.getPermutationSceneIdBySlug(slug);

        if (!sceneId && slug === permutableTypes.LOGO) {
          console.log('no template scene id found in logo, checking text-logo');
          // some instance that template logo is acutally a text-logo,
          // try to get the scene id of text-logo since the sceneId is already undefined but is required for creating a scene in logo template.
          sceneId = this.getPermutationSceneIdBySlug(permutableTypes.TEXT_LOGO);
          // add more fall back for logos
          if (!!sceneId === false) {
            const permutationSceneValues = Object.values(this.permutationScenesIds);
            if (permutationSceneValues.length) {
              sceneId = permutationSceneValues[0];
            }
          }

          // if still nothing found. throw an error
          if (!!sceneId === false) {
            console.error(`JSON error: ${JSON.stringify(this.permutationScenesIds)}`);
          }
        }

        console.log('addScene!!!!', additionalParams, params, sceneId, slug);
        // console.log('addScene sceneId', sceneId, slug);
        params.template_scene_id = sceneId;
      }
      this.setShowCanvasPreloader(true);

      const APIREQUEST = this.getIsTemplate ? TemplateApi : ProjectApi;

      APIREQUEST.addProjectScene(this.getProjectId, params)
        .then((response) => {
          console.log('completed', response.data);
          if (response.data.success) {
            const result = response.data.results;
            this.addSceneToArray({
              newId: result.id,
              background: result.background,
              duration: result.duration,
              elements: result.elements,
              transition: result.transition,
            });

            this.setShowSidebar(true);
            this.setActiveGroup('texts');
            this.updateActiveSceneId(result.id);

            if (this.$route.name === 'smart-create') {
              // if (isMobile()) {
              //   // TODO: You're Almost done screen
              //   this.$root.$emit('almost-done');
              // } else {

              // if (typeof this.isIframe !== 'undefined' && this.isIframe) {
              //   window.parent.postMessage({
              //     projectId: this.getProjectId,
              //     query: {
              //       hide_add_new_scene_modal: true,
              //     },
              //   }, iframeParentURL());
              //   return;
              // }

              this.$router.push({ name: 'project', params: { projectId: this.getProjectId }, query: { hide_add_new_scene_modal: true } });
              // }
            }
            // console.log(this.getActiveGroup);
            setTimeout(() => {
              this.setShowCanvasPreloader(false);
              if (!isMobile()) {
                this.$root.$emit('canvas-container-reset-timeline');
              }
            }, 2000);

            this.setShowRemoveBgOption(false);

            this.updateCanvasSizeWithTemplate({
              width: this.getCanvasSize.width,
              height: this.getCanvasSize.height,
            });
          }
        }).catch((error) => {
          console.log(error);
          this.setShowCanvasPreloader(false);
        });
    },
    openUploadModal(isSample) {
      this.setIsModalVisible(true);
      this.clickedImageIndex = 0;
      if (this.isLogo) {
        // if it's sample,
        // automatically use offeo logo
        if (isSample) {
          const url = `${process.env.VUE_APP_CDN_ASSETS}/smart-create/offeo-logo.png`;
          this.selectImage(url);
          this.setConfirmRemoveBg(false);
          this.setIsModalVisible(false);
          this.uploadSmartCreateFile();
          this.$emit('next-step');
        } else {
          this.openReplaceLogoOverlay();
        }
      } else {
        if (isSample) this.preSelectedTab = 'sample';
        else this.preSelectedTab = 'user-files';
        this.openReplaceImagesOverlay();
      }
    },
    openReplaceImagesOverlay() {
      this.isReplacingImages = true;
    },
    openReplaceLogoOverlay() {
      this.isReplacingLogo = true;
    },
    smartCreateTrackingUpdate(params = {}) {
      /*
        STEPS:
        1. upload image
        2. waiting for permutation to load
        3. selection in page 1
        4. waiting for page 2,3,4 to load
        5. canvas
      */

      // console.log(this.smartCreateTrackingId);

      if (this.userId) {
        // eslint-disable-next-line
        params.user_id = this.userId;
      } else {
        // eslint-disable-next-line
        params.tmp_user_id = this.tmpUserId;
      }

      if (this.smartCreateTrackingId) {
        Api.smartCreateTracking(params, this.smartCreateTrackingId)
          .then((response) => {
            console.log('smart create tracking updated', response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        Api.smartCreateTracking(params)
          .then((response) => {
            console.log('smart create tracking created', response);
            const { results } = response.data;
            if (results) {
              this.setSmartCreateTrackingId(results.id);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  beforeMount() {
    this.images = [];

    for (let i = 0; i < this.maxImages; i += 1) {
      this.images.push(cloneDeep(this.imageFormat));
    }
  },
};

export default uploadPermutationMixin;
