import {
  mapGetters, mapMutations, mapActions, mapState,
} from 'vuex';

import cloneDeep from 'lodash.clonedeep';
import { groups, subgroups } from '@/assets/scripts/enums';
import { _time, elementFormat, allowNonLoginUserUpload } from '@/assets/scripts/variables';
import {
  randomId,
  isFacebookApp,
  getImageSize,
  isEqualFile,
  isMobile,
} from '@/assets/scripts/utilities';
import helperMixins from '@/components/mixins/helper-mixins';

const BeforeUploadOverlay = () => import('@/components/ProjectCanvas/PageOverlay/BeforeUploadOverlay.vue');

// eslint-disable-next-line
const tus = require('tus-js-client');
const ResumableJs = require('resumablejs');

const uploadingMixin = {
  components: {
    BeforeUploadOverlay,
  },
  mixins: [helperMixins],
  data() {
    return {
      // chunkUploadPercentage: 0,
      // uploadedFileType: '',
      elementData: cloneDeep(elementFormat),
      isDropFile: false,
      pauseUpload: false,
      isUploadCancelled: false,
      showUploadingModal: false,
      isDoneUploading: false,
      isSaving: false,
      upload: null,
      isProcessing: false,
      dropFileName: '',
      chunkSize: this.mbToBytes(10), // 10mb
      simultaneousUploads: 5,
      files: [],
      validFiles: [],
      inValidFiles: [],
      fileCompleted: [],
      resumable: '',
      showUpgradeButton: false,
      isFileError: false,
      fileErrorMessage: '',
      isImageLogoOverlay: false,
      isBrandSetup: false,
      unUploadedVideos: [],
      uploadingVideos: [],
      uploadedVideos: [],
      allowedVideoFormats: ['video/mp4', 'video/quicktime', 'video/webm'],
      notifiedFiles: [],
      isVideoUploading: false,
      insertToCanvas: false,
      maxFilenameLength: 50,
      allowClose: false,
      isMobile: isMobile(),
      allowNonLoginUserUpload: allowNonLoginUserUpload(),

      // uploading error notification - try again button functionality states
      previousVideoUploadTrimPoints: {},
      currentErrorVideoFile: [], // for video upload failures
      currentErrorFile: [], // for other asset upload failures ( e.g images, audios, etc )

      activeFileSpace: 'personal', // ( e.g personal, team )
    };
  },
  computed: {
    ...mapState(['isLoggedIn', 'showSwap', 'showLiteReplaceModal', 'showLiteUploadMusic']),
    ...mapState('userData', ['storageSpaceLeft']),
    ...mapState('assetsSidebar', ['skeletonList', 'isTrimmingVideosBeforeUpload']),
    ...mapState('inspirationList', ['tmpUserId', 'confirmRemoveBg', 'userFileId']),
    ...mapGetters(['showStoryboardOverlay', 'getCanvasZoom', 'isLiteMode']),
    ...mapGetters('userData', ['isFreeUser', 'isDesigner', 'isAdmin']),
    ...mapGetters('assetsSidebar', ['getActiveGroup', 'getActiveSubGroup', 'getActiveFileCategory', 'getSidebarElements']),
    ...mapGetters('canvasElements', [
      'getActiveElements',
      'getCanvasElements',
      'getSwappedElement',
      'getCurrentSceneDuration',
      'getCanvasSize',
    ]),
    ...mapState('assetsSidebar', ['activeFolder']),
    allowedFormat() {
      return this.validExtensions.join(', ').toUpperCase();
    },
    showUploadButtons() {
      return (
        (this.getActiveGroup === groups.MUSIC && this.getActiveSubGroup === subgroups.UPLOAD)
        || this.getActiveGroup === groups.MYFILES
      );
    },
    mobileFileAccept() {
      /**
       * Facebook in app browser doesn't accept :accept attribute
       * https://stackoverflow.com/questions/27000708/file-upload-control-not-working-in-facebook-in-app-browser
       */
      if (isFacebookApp()) return '';
      return this.fileAccept;
    },
    fileAccept() {
      return `.${this.validExtensions.join(', .')}`;
    },
    errorResponse() {
      const error = {
        file_required: 'File required',
        file_details: 'Unable to get the file details',
        invalid_file: `Please upload allowed format (${this.allowedFormat})`,
        file_size_exceeded: 'File size too big, please upload less than of 1G',
        resumablejs_not_supported:
          'Something went wrong while trying to upload your file. Please contact OFFEO support at help@offeo.com',
      };

      if (this.isFreeUser) {
        error.file_size_exceeded = 'Please upgrade your account to upload more than 100mb';
      }

      return error;
    },
    chunkUploadProgress(val) {
      return val;
    },
    isMusicUpload() {
      return this.uploadedFileType.indexOf('audio') !== -1;
    },
    isVideoUpload() {
      return (
        this.uploadedFileType.indexOf('video') !== -1
        || (this.uploadedVideos && this.uploadedVideos.length)
      );
    },
    isImageUpload() {
      return this.uploadedFileType.indexOf('image') !== -1;
    },
    uploadingText() {
      if (this.isProcessing) {
        return `File is uploaded and being processed. You will be notified when ${this.dropFileName} is ready to be used.`;
      }
      if (this.chunkUploadPercentage < 100) {
        // return `Uploading file... (${parseFloat(this.chunkUploadPercentage).toFixed(2)}%)`;
        let content = '';
        Object.keys(this.validFiles).forEach((index) => {
          const { fileName, fileProgress, isFailed } = this.validFiles[index];
          let progress = `<span>(${fileProgress}%)</span>`;
          if (isFailed) {
            progress = '<i class="icon icon-cancel"></i>';
          } else if (fileProgress >= 100) {
            progress = '<i class="t--blue icon icon-checked"></i>';
          }
          content += `<span style="display: block;text-align: left;">${progress} ${fileName}</span>`;
        });

        // failed upload files
        Object.keys(this.inValidFiles).forEach((index) => {
          const { fileName, errorType } = this.inValidFiles[index];
          // eslint-disable-next-line
          content += `<span style="display: block;text-align: left;"><i class="icon icon-cancel"></i> ${fileName} - <small><i>${errorType}</i></small></span>`;
        });

        return content;
      }
      return 'Saving your file(s)';
    },
    validExtensions() {
      const validExtensions = [];

      const isMusicTab = this.getActiveGroup === groups.MUSIC;
      // ensure only the right tab will show the right extensions

      if (this.isImageLogoOverlay || this.isBrandSetup || this.showLiteReplaceModal) {
        // for smart create and storyboard
        // for lite mode replace image
        validExtensions.push('jpg', 'jpeg', 'png'); // image extensions
      } else if (!this.showSwap) {
        if (isMusicTab || this.isDropFile || this.showLiteUploadMusic) validExtensions.push('mp3'); // audio extensions
        if (!isMusicTab || this.isDropFile) validExtensions.push('jpg', 'jpeg', 'png'); // image extensions
        if (!isMusicTab || this.isDropFile) validExtensions.push('mp4', 'mov', 'webm'); // video extensions
      } else {
        // If its swapping, allowed extensions depend on swapping element type
        switch (this.swappingElementType) {
          case 'images':
            validExtensions.push('jpg', 'jpeg', 'png');
            break;
          case 'videos':
            validExtensions.push('mp4', 'mov', 'webm');
            break;
          default:
            break;
        }
      }

      if (
        (this.isDesigner || this.isAdmin)
        && !isMusicTab
        && !this.showSwap
        && !this.showLiteReplaceModal
      ) {
        validExtensions.push('svg'); // append svg for designers
      }

      return validExtensions;
    },
    chunkUploadPercentage() {
      let totalProgress = 0;
      let totalItems = 0;
      Object.keys(this.validFiles).forEach((index) => {
        totalProgress += this.validFiles[index].fileProgress;
        totalItems += 1;
      });

      return totalProgress / totalItems;
    },
    uploadedFileType() {
      let uploadedFileType = '';
      let fileIndex = 0;

      Object.keys(this.validFiles).forEach((index) => {
        if (fileIndex === 0) {
          uploadedFileType = this.validFiles[index].fileType;
          fileIndex += 1;
        }
      });

      return uploadedFileType;
    },
    isSmartCreateComponent() {
      return (
        this.$options.name === 'ReplaceLogoOverlay' || this.$options.name === 'ReplaceImagesOverlay'
      );
    },
    swappingElementType() {
      if (!this.showSwap) return null;
      const { type } = this.getSwappedElement;
      return type;
    },
  },
  methods: {
    ...mapActions('canvasElements', ['addElementToCanvas']),
    ...mapMutations('assetsSidebar', [
      'setActiveCategory',
      'clearActiveCategory',
      'setActiveGroup',
      'setActiveSubGroup',
      'addSkeletonList',
      'deleteSkeletonList',
      'updateSkeletonList',
      'clearSkeletonList',
      'updateSidebarElement',
      'setIsTrimmingVideosBeforeUpload',
      'setIsUploadingFile',
      'setIsUploadingMusicFile',
    ]),
    ...mapMutations('canvasElements', ['updateSwappedElement']),
    ...mapMutations('canvasElements', ['updateSwappedElement']),
    ...mapMutations('userData', ['setStorageSpaceLeft']),
    ...mapMutations('inspirationList', ['setInspirationTemplateTags']),
    isValidExtension(file) {
      // eslint-disable-next-line
      const regex = new RegExp(`(.*?)\.(${this.validExtensions.join('|')})$`);
      return regex.test(file.toLowerCase());
    },
    isFileSizeExceed(size) {
      let fileLimit = this.mbToBytes(100); // 100mb file limit for free user;

      if (this.isDesigner || !this.isFreeUser) {
        fileLimit = this.mbToBytes(1000); // 1G file limit for designer and non free users
      }

      // eslint-disable-next-line
      return parseInt(size) > fileLimit;
    },
    alertFileError(message = 'Uploading failed', useAlertUpload = false) {
      this.doClearFileInput(); // clear input file upload

      if (useAlertUpload) {
        this.alertUpload('error', message, 7000, true);
        this.uploadingVideos = [];
      } else {
        this.isFileError = true;
        this.fileErrorMessage = message;
      }

      // this.showUploadingModal = false;
      this.isSaving = false;
      this.isDoneUploading = true;
      this.setIsUploadingMusicFile(false);
      // return alert(message);
    },
    liteModeInvalidFileHandler() {
      this.isDropFile = false;
      this.insertToCanvas = false;
      this.alertError(
        '',
        'Uploading cancelled, invalid file detected',
        3000,
        'TopCenterNotif',
        true,
      );
    },
    doDropFile(e, type) {
      if (this.showStoryboardOverlay) return;
      this.isDropFile = true;
      // this.insertToCanvas = e.target.classList.contains('scene');
      this.insertToCanvas = true;
      // add checker so it won't run when draggable is triggered
      if (!e.dataTransfer || !e.dataTransfer.files || e.dataTransfer.files.length === 0) return;
      const { files } = e.dataTransfer;

      if (files.length) {
        for (let i = 0; i < files.length; i += 1) {
          const file = files[i];

          if (type === 'music' && !file.type.includes('audio')) {
            // for lite mode music tab, only allow music
            this.liteModeInvalidFileHandler();
            break;
          }

          // console.log('file is', file)
          if (this.allowedVideoFormats.includes(file.type)) {
            if (this.isLiteMode) {
              // user is not allowed to upload video in lite mode
              this.liteModeInvalidFileHandler();
              break;
            }
            this.unUploadedVideos.push(file);
          } else {
            this.files.push(file);
          }
        }

        if (this.unUploadedVideos.length) {
          this.setIsTrimmingVideosBeforeUpload(true);
        }

        // console.log('doDropFile!!!', this.files, this.unUploadedVideos);
        if (this.files.length) {
          this.$nextTick(() => {
            this.doCheckFiles();
          });
        }
      }
    },
    strSlug(fileName, separator) {
      // eslint-disable-next-line
      if (typeof separator === 'undefined') separator = '-';

      // Convert all dashes/underscores into separator

      let text = fileName.slice(0, fileName.indexOf('.'));
      const extension = fileName.split('.').pop();

      // eslint-disable-next-line
      const flip = separator == '-' ? '_' : '-';

      text = text.replace(flip, separator);

      // Remove all characters that are not the separator, letters, numbers, or whitespace.
      text = text.toLowerCase().replace(new RegExp(`[^a-z0-9${separator}\\s]`, 'g'), '');

      // Replace all separator characters and whitespace by a single separator
      text = text.replace(new RegExp(`[${separator}\\s]+`, 'g'), separator);

      return `${text.replace(
        new RegExp(`^[${separator}\\s]+|[${separator}\\s]+$`, 'g'),
        '',
      )}.${extension}`;
    },
    doStartChunkUpload() {
      this.resumable.upload();
      this.pauseUpload = false;
      console.log('START UPLOAD');
    },
    doPauseChunkUpload() {
      this.resumable.pause();
      this.pauseUpload = true;
      // console.log('PAUSE UPLOAD');
    },
    cancelUpload() {
      this.doCancelChunkUpload();
      this.isUploadCancelled = true;
    },
    confirmUploadingOverlay() {
      if (this.isProcessing) {
        // this.showUploadingModal = false;
        this.resetData();
      } else if (this.chunkUploadPercentage >= 100 || this.allowClose) {
        this.doCancelChunkUpload();
      }
    },
    closeUploadingOverlay() {
      // Only can close when uploading is done
      if (this.isProcessing) {
        // this.showUploadingModal = false;
      }
    },
    doSelectFile(ref = null) {
      this.isDropFile = false;
      let files = [];
      // Using ref is for selecting video form Video Trimmer Model
      if (ref && ref.files) {
        // eslint-disable-next-line prefer-destructuring
        files = ref.files;
      } else if (this.$refs.mobileFileUpload && this.$refs.mobileFileUpload.files) {
        // eslint-disable-next-line prefer-destructuring
        files = this.$refs.mobileFileUpload.files;
      } else if (this.$refs.fileUpload && this.$refs.fileUpload.files) {
        // eslint-disable-next-line prefer-destructuring
        files = this.$refs.fileUpload.files;
      } else if (this.$refs.cameraUpload && this.$refs.cameraUpload.files) {
        // eslint-disable-next-line prefer-destructuring
        files = this.$refs.cameraUpload.files;
      }

      if (files.length) {
        for (let i = 0; i < files.length; i += 1) {
          const file = files[i];
          let fileName = file.name;
          if (fileName.length > this.maxFilenameLength) {
            // split and rename
            const fileExt = fileName.split('.').pop();
            fileName = `${fileName.substring(0, this.maxFilenameLength)}.${fileExt}`;
          }

          const newFile = new File([file], fileName, { type: file.type });
          if (this.allowedVideoFormats.includes(file.type)) {
            this.unUploadedVideos.push(newFile);
          } else {
            this.files.push(newFile);
          }
        }

        if (this.unUploadedVideos.length) {
          this.setIsTrimmingVideosBeforeUpload(true);
        }

        // console.log('doSelectFile!!!', this.files, this.unUploadedVideos);
      }

      if (this.files.length) {
        this.$nextTick(() => {
          this.doCheckFiles();
        });
      }
    },
    /* eslint-disable */
    doCheckFiles() {
      this.activeFileSpace = this.getActiveFileCategory;

      if (this.showFileUploadOption) {
        this.mobileHideFileUpload();
      }
      this.isSaving = false;
      this.isDoneUploading = false;
      this.isUploadCancelled = false;
      if (this.files.length === 0) return;

      const isAudioFileUploaded = this.files.some(file => file.type.includes('audio'));
      console.log(isAudioFileUploaded);
      if (isAudioFileUploaded) this.setIsUploadingMusicFile(true);

      if (!this.isLoggedIn && !this.allowNonLoginUserUpload) {
        // under smart create, user may not logged in yet,
        // skip this part and upload temp file
        this.showPreloader = true;
        this.alertError('', 'Uploading cancelled, login required.', 3000, 'TopCenterNotif', true);
        return;
      }

      if (this.isSmartCreateComponent && this.files[0].type.indexOf('image') === -1) {
        this.showInvalidFileTypeError();
        return;
      }

      // after all validations passed, continue uploading the file
      const apiEndpoint = process.env.VUE_APP_OFFEO_API;

      const isLogoUpload = this.files[0].type.indexOf('image') !== -1 && this.getActiveSubGroup === 'logos';
      // console.log('check files', this.files[0].type.indexOf('image'), this.files, this.getActiveSubGroup);

      const additionalMetadata = {
        is_logo: isLogoUpload,
        project_folder: this.activeFolder,
      };

      const uploadEndpoint = this.isLoggedIn ? 'upload-file-chunks' : 'tmp-upload-file';

      const resumableJsQuery = {
        withCredentials: true,
        chunkSize: this.chunkSize,
        simultaneousUploads: this.simultaneousUploads,
        testChunks: false,
        throttleProgressCallbacks: 1,
        target: `${apiEndpoint}/v2/${uploadEndpoint}?timestamp=${_time(true)}`,
        headers: {
          'Upload-Metadata-Additional': JSON.stringify(additionalMetadata),
        },
      };

      if (!this.isLoggedIn) {
        resumableJsQuery.query = {
          user_id: this.tmpUserId,
          _tmp_user_id: this.tmpUserId,
          provider: 'temporary_file',
          is_logo: this.isLogo,
          confirm_remove_bg: this.confirmRemoveBg,
          user_file_id: this.userFileId,
        };
      }

      this.resumable = new ResumableJs(resumableJsQuery);

      if (!this.resumable.support) {
        this.showUpgradeButton = false;
        // eslint-disable-next-line
        this.alertFileError(errors.resumablejs_not_supported);
        return;
      }

      Object.keys(this.files).forEach((index) => {
        const file = this.files[index];
        const fileName = file.name;

        if (this.isValidExtension(fileName) && !this.isFileSizeExceed(file.size)) {
          this.resumable.addFile(file);
        } else {
          const errorType = this.isFileSizeExceed(file.size)
            ? this.errorResponse.file_size_exceeded
            : this.errorResponse.invalid_file;

          this.inValidFiles.push({
            // eslint-disable-next-line
            index: parseInt(index),
            fileName,
            errorType,
          });
        }
      });

      // console.log(this.resumable.files);

      // all files failed in validations, stop uploading process
      if (this.inValidFiles.length === this.files.length) {
        this.showInvalidFileTypeError();
        return;
      }

      let progressIndex = 0;

      // Handle file add event
      this.resumable.on('fileAdded', (file) => {
        progressIndex = 0;
        const fileSkeleton = {
          id: file.uniqueIdentifier,
          fileType: file.file.type,
          fileName: file.fileName,
          fileProgress: 0,
          isFailed: false,
          isPreparing: false,
        };
        // to prevent duplicate of valid files
        if (!this.validFiles[file.uniqueIdentifier]) {
          this.$set(this.validFiles, file.uniqueIdentifier, fileSkeleton);
        }
        if (file.file.type.indexOf('image') >= 0) {
          fileSkeleton.type = this.getActiveSubGroup === 'logos' ? 'logos' : 'images';
        }
        if (file.file.type.indexOf('video') >= 0) fileSkeleton.type = 'videos';

        // console.log('addSkeletonList', fileSkeleton);
        this.addSkeletonList(fileSkeleton);
        this.doStartChunkUpload();
      });

      this.resumable.on('fileError', (errorFile, res) => {
        progressIndex = 0;

        const errorFileTypeText = this.getFileTypeText(errorFile.file);
        let error = {};
        try {
          error = JSON.parse(res);
        } catch (e) {
          error = res;
        }

        // this.doCancelChunkUpload();
        if (!this.isMobile || !document.getElementsByClassName('smart-create')[0]) {
          this.alertFileError(`${errorFileTypeText} Upload Fail`, true);
          this.currentErrorFile = [errorFile.file];
          this.clearSkeletonList();
        } else {
          this.alertFileError(error.message);
        }

        if (error.file_limit === true) {
          this.showUpgradeButton = error.show_upgrade_button;
          this.isDoneUploading = true;
          this.allowClose = true;
          this.deleteSkeletonList(errorFile.uniqueIdentifier);
        }

        const newFileName = `${errorFile.fileName} - <span><i>${error.message}</i></small>`;
        if (this.validFiles[errorFile.uniqueIdentifier]) {
          this.$set(this.validFiles[errorFile.uniqueIdentifier], 'fileName', newFileName);
          this.$set(this.validFiles[errorFile.uniqueIdentifier], 'isFailed', true);
        }

        this.updateSkeletonList({
          id: errorFile.uniqueIdentifier,
          fileName: newFileName,
          isFailed: true,
        });

        this.setStorageSpaceLeft(error.space_left || 'unlimited');
        this.isUploadCancelled = false;
      });

      this.resumable.on('fileProgress', (file) => {
        const fileUID = file.uniqueIdentifier;
        // Handle progress for both the file and the overall upload
        // console.log('fileprogress');
        if (this.isUploadCancelled) {
          this.resumable.cancel();
        }
        const fileProgress = Math.floor(file.progress() * 100);
        // console.log('fileProgress', file);

        this.validFiles[fileUID].fileProgress = fileProgress;
        // console.log('skeletonList are', this.skeletonList, fileUID, this.validFiles[fileUID]);
        this.updateSkeletonList({
          id: fileUID,
          fileProgress,
        });

        // console.log('fileProgress is', fileUID, progressIndex);

        if (progressIndex === 0 && !this.isMusicUpload) this.doLoadActiveGroup();
        progressIndex += 1;
      });

      this.resumable.on('fileSuccess', (succeededFile, res) => {
        // Reflect that the file upload has completed
        // eslint-disable-next-line
        res = JSON.parse(res);
         // v1 and v2 API results could be different
        const user_files = res.user_files || (res.results && res.results.user_files);
        const user_files_data = (user_files.data && user_files.data[0]) || user_files;
        const user_files_detail = {
          ext: user_files.ext[0] || user_files.ext,
          file:user_files_data.download_link || user_files_data.file_hd || user_files_data.file,
          file_name:user_files_data.original_name || user_files_data.file_name,
          thumb_link:user_files_data.thumb_link || user_files_data.preview_url,
        }

        console.log('fileSuccess', res, succeededFile.uniqueIdentifier);
        if (res.success) {
          if (this.chunkUploadPercentage >= 100) {
            this.isSaving = false;
            this.isDoneUploading = true;
            // this.showUploadingModal = false;

            if (!this.isMobile || !document.getElementsByClassName('smart-create')[0]) {
              // if it's in mobile and smart create, dont' show the success popup

              const getUploadedFilesToResumable = this.resumable.files.map((resumableFile) => { return resumableFile.file });
              let uploadedFileTypeText = [];
              for (let index = 0; index < getUploadedFilesToResumable.length; index++) {
                const fileTypeText = this.getFileTypeText(getUploadedFilesToResumable[index]);
                const isFileTypeTextAdded = uploadedFileTypeText.includes(fileTypeText);
                if (!isFileTypeTextAdded) uploadedFileTypeText.push(fileTypeText);
              }

              for (let index = 0; index < uploadedFileTypeText.length; index++) {
                // so even though user upload audio & image together, the user can be notified that audio & image has been uploaded
                this.alertUpload('success', `${uploadedFileTypeText[index]} Uploaded`, 7000);
              }
            }

            // this.doLoadActiveGroup();
            this.doClearFileInput();
            this.setIsUploadingFile(false);
            // this is for ReplaceImageOverlay and ReplaceLogoOverlay
            if (this.isImageLogoOverlay) this.getImages();
            if (this.isBrandSetup) this.updateLogos(user_files_detail.thumb_link);
          }

          // if on mobile
          // if there is no previous file, directly use it
          // if user already has files, open replace modal
          if (this.isMobile && document.getElementsByClassName('smart-create')[0]) {
            console.log('emit image uploaded', user_files);
            const userFile = user_files || res.temporary_files[0] || '';
            this.$emit('image-uploaded', userFile);
            // const userFile = user_files || res.temporary_files[0] || '';
            // this.onMobileUploadSuccess(userFile);
            return;
          }

          this.deleteSkeletonList(succeededFile.uniqueIdentifier);

          if (this.showSwap) {
            const data = user_files_data;
            const item = {
              id: user_files.id,
              user_id: user_files.user_id,
              folder_id: user_files.folder_id,
              file: user_files_detail.file,
              file_name: user_files_detail.file_name,
              preview_url: user_files_detail.thumb_link,
              ext: user_files_detail.ext,
              is_preparing: user_files.is_preparing,
            };
            if (data.width) {
              item.width = data.width;
            }
            if (data.height) {
              item.height = data.height;
            }
            this.doUpdateSwappedElement(item);
            if (this.getSidebarElements[`my-files/images/${this.activeFileSpace}`]) {
              this.updateSidebarElement({
                id: item.id,
                group: 'my-files',
                subgroup: 'images',
                slug: this.activeFileSpace,
                data: item,
              });
            }
          } else if (user_files_detail.ext === 'mp3') {
            if (this.getSidebarElements['music/upload/all']) {
              const data = user_files_data;
              const item = {
                id: user_files.id,
                artist: data.artist ? data.artist : 'Anonymous',
                file: user_files_detail.file,
                title: data.title ? data.title : user_files_detail.file_name,
              };

              this.updateSidebarElement({
                id: item.id,
                group: 'music',
                subgroup: 'upload',
                slug: 'all',
                data: item,
              });
            } else {
              this.doLoadActiveGroup('music');
            }
          } else if (
            user_files_detail.ext !== 'mp3'
            && user_files_detail.ext !== 'mp4'
            && user_files_detail.ext !== 'mov'
            && user_files_detail.ext !== 'webm'
          ) {
            // music - no need to insert to  canvas
            // video - no need to insert to canvas as it has post upload process. (conversion, re-uploading etc.)

            // need to cater for logos as well
            const imageType = this.getActiveSubGroup === 'logos' ? 'logos' : 'images';

            const data = user_files_data;
            const item = {
              id: user_files.id,
              user_id: user_files.user_id,
              folder_id: user_files.folder_id,
              file: data.w_960 || user_files_detail.file, // check if w_960 exists, then use the file
              file_name: user_files_detail.file_name,
              preview_url: user_files_detail.thumb_link,
              ext: user_files_detail.ext,
              is_preparing: user_files.is_preparing,
            };

            if (this.insertToCanvas) {
              this.doAddElementToCanvas(item);
            }

            if (this.getSidebarElements[`my-files/${imageType}/${this.activeFileSpace}`]) {
              this.updateSidebarElement({
                id: item.id,
                group: 'my-files',
                subgroup: imageType,
                slug: this.activeFileSpace,
                data: item,
              });
            }
          }
          this.setStorageSpaceLeft(res.space_left || 'unlimited');
        } else {
          // this.doCancelChunkUpload();
          // this.isUploadCancelled = true;
          this.$set(this.validFiles[succeededFile.uniqueIdentifier], 'isFailed', true);
          this.updateSkeletonList({
            id: succeededFile.uniqueIdentifier,
            isFailed: true,
          });

          if (!this.isMobile || !document.getElementsByClassName('smart-create')[0]) {
            const errorFileTypeText = this.getFileTypeText(succeededFile.file);
            this.alertFileError(`${errorFileTypeText} Upload Fail`, true);
            this.currentErrorFile = [succeededFile.file];
            } else {
            this.alertError('', res.message, 3000, 'TopCenterNotif', true);
          }
        }

        this.files = []; // clear files after uploaded / failed
        this.fileCompleted.push(succeededFile.fileName);
        this.setIsUploadingMusicFile(false);
      });

      this.resumable.on('cancel', () => {
        // this.doCancelChunkUpload();
        this.isUploadCancelled = true;
        this.files = []; // clear files after canceling
        this.setIsUploadingMusicFile(false);
      });

      this.$nextTick(() => {
        // this.showUploadingModal = true;
      });
    },
    /* eslint-enable */
    doClearFileInput() {
      // will reset all input and history of upload file
      this.files = [];
      this.validFiles = [];
      this.inValidFiles = [];
      this.fileCompleted = [];

      if (this.$refs.mobileFileUpload && this.$refs.mobileFileUpload.files) {
        this.$refs.mobileFileUpload.value = '';
      } else if (this.$refs.fileUpload && this.$refs.fileUpload.files) {
        this.$refs.fileUpload.value = '';
      }
    },
    doCancelChunkUpload() {
      this.resumable.cancel();
      this.upload = null;
      this.doClearFileInput();
      // this.showUploadingModal = false;
      this.allowClose = false;
    },
    doAddElementToCanvas(item = '') {
      // console.log('doAddElementToCanvas', item);
      if (!item) return;

      const treatAsGraphics = (this.isDesigner || this.isAdmin)
        && this.getActiveGroup !== groups.MUSIC
        && item.file.split('.').pop() === 'svg';

      this.elementData.data.id = randomId();
      this.elementData.type = treatAsGraphics ? 'graphics' : 'images';
      this.elementData.isImage = !treatAsGraphics;
      this.elementData.filters = {};
      this.elementData.data.thumb = item.preview_url;
      this.elementData.data.url = item.file;
      this.elementData.data.urlHd = item.file;
      this.elementData.menu = treatAsGraphics ? 'graphics' : this.getActiveGroup;
      this.elementData.file_id = item.id;
      this.elementData.is_brand = this.getActiveSubGroup === 'logos';
      this.elementData.time_out = this.getCurrentSceneDuration;
      this.elementData.timeline_settings.animationDuration = this.elementData.time_out - this.elementData.time_in;
      this.elementData.timeline_settings.animateOutStart = this.elementData.time_out - this.elementData.timeline_settings.animateOutDuration;
      this.elementData.data.name = this.elementData.type;

      if (this.elementData.type.width !== 'auto' && this.elementData.size.width > 700) {
        const ratio = this.elementData.size.width / 700;
        this.elementData.size = {
          width: 700,
          height: this.elementData.size.height / ratio,
        };
      }

      if (!this.elementData.isImage) {
        this.$nextTick(() => {
          console.log('this.elementData upload ~ not image', this.elementData);
          this.addElementToCanvas(this.elementData);
        });
      } else {
        // if image upload, check the size dimension first
        // same as CategoryItem.vue@processGetElementSize()
        getImageSize(this.elementData.data.url, (imageWidth, imageHeight) => {
          const maxWidth = this.getCanvasSize.width * 0.75;
          const maxHeight = this.getCanvasSize.height * 0.75;

          const maxSize = maxWidth > maxHeight ? maxHeight : maxWidth; // get the lowest value;

          let imageRatio = 1;
          let newImageWidth = imageWidth;
          let newImageHeight = imageHeight;

          if (imageWidth > maxSize || imageHeight > maxSize) {
            if (imageWidth > maxSize) {
              imageRatio = maxSize / newImageWidth;
              newImageWidth = maxSize;
              newImageHeight = imageHeight * imageRatio;
            }
            if (newImageHeight > maxSize) {
              // vertical
              imageRatio = maxSize / newImageHeight;
              newImageHeight = maxSize;
              newImageWidth *= imageRatio;
            }
          }

          this.elementData.size = {
            width: newImageWidth,
            height: newImageHeight,
          };

          this.elementData.position = {
            x: this.getCanvasSize.width / 2,
            y: this.getCanvasSize.height / 2,
          };
          // need to wait for the image size first
          this.$nextTick(() => {
            // console.log('this.elementData upload ~ image', this.elementData);
            this.addElementToCanvas(this.elementData);
          });

          // console.log('getImageSize', this.elementData);
        });
      }
    },
    doUpdateSwappedElement(item = '') {
      if (!item) return;

      const treatAsGraphics = (this.isDesigner || this.isAdmin)
        && this.getActiveGroup !== groups.MUSIC
        && item.file.split('.').pop() === 'svg';

      this.elementData.isImage = !treatAsGraphics;
      this.elementData.data.thumb = item.preview_url;
      this.elementData.data.url = item.file;
      this.elementData.data.urlHd = item.file;
      this.elementData.data.name = this.elementData.type;

      if (item.width && item.height) {
        this.elementData.size = {
          width: item.width,
          height: item.height,
        };
      } else {
        getImageSize(this.elementData.data.url, (imageWidth, imageHeight) => {
          this.elementData.size = {
            width: imageWidth,
            height: imageHeight,
          };
        });
      }

      this.$nextTick(() => {
        this.updateSwappedElement(this.elementData);
      });
    },
    doLoadActiveGroup(type = '') {
      // if (this.isDropFile) return; // disable switching tabs when multiple files are uploaded
      if (this.isLiteMode) return; // for lite mode, don't change tab

      // we need to flatten so it wont break the upload work flow;
      const isVideoUpload = JSON.parse(JSON.stringify(this.isVideoUpload));
      const isImageUpload = JSON.parse(JSON.stringify(this.isImageUpload));
      const isMusicUpload = JSON.parse(JSON.stringify(this.isMusicUpload)) || type === 'music';
      // console.log('Load active group.', isVideoUpload, isImageUpload, isMusicUpload);

      if (isMusicUpload) {
        this.setActiveGroup('music');
        this.$nextTick(() => {
          this.setActiveSubGroup('upload');
        });
      } else if (this.getActiveGroup === 'my-files') {
        let setActiveSubGroup = 'videos';
        // image upload
        if (!this.isVideoUpload) {
          setActiveSubGroup = this.getActiveSubGroup === 'logos' ? 'logos' : 'images';
          this.clearActiveCategory();
          // this.$nextTick(() => {
          //   this.doAddElementToCanvas();
          // });
        }
        this.setIsUploadingFile(true);
        this.setActiveSubGroup(setActiveSubGroup);
        this.clearActiveCategory();
      } else {
        // my-files tab not active
        // this.$nextTick(() => {
        //   console.log('next tick', this.getActiveSubGroup);
        if (isVideoUpload) {
          this.setActiveSubGroup('videos');
        } else if (isImageUpload) {
          this.setActiveSubGroup('images');
          // this.$nextTick(() => {
          //   this.doAddElementToCanvas();
          // });
        }
        this.setActiveGroup('my-files');
        this.clearActiveCategory();
        // });
      }

      /** **************** */

      // if (this.isMusicUpload) {
      //   this.setActiveGroup('music');
      //   this.$nextTick(() => {
      //     this.setActiveSubGroup('upload');
      //   });
      // } else if (this.getActiveGroup !== 'my-files') {
      //   const isVideo = this.uploadedFileType.indexOf('video') !== -1;
      //   const isImage = this.uploadedFileType.indexOf('image') !== -1;

      //   this.setActiveGroup('my-files');
      //   this.$nextTick(() => {
      //     if (isVideo) {
      //       this.setActiveSubGroup('videos');
      //     } else if (isImage) {
      //       this.setActiveSubGroup('images');
      //       this.$nextTick(() => {
      //         this.doAddElementToCanvas();
      //       });
      //     }
      //     this.clearActiveCategory();
      //   });
      // } else {
      //   const isVideo = this.uploadedFileType.indexOf('video') !== -1;
      //   const isImage = this.uploadedFileType.indexOf('image') !== -1;
      //   if (isVideo) {
      //     this.setActiveSubGroup('videos');
      //   } else if (isImage) {
      //     if (this.getActiveSubGroup === 'logos') {
      //       this.setActiveSubGroup('logos');
      //     } else {
      //       this.setActiveSubGroup('images');
      //     }
      //     this.clearActiveCategory();
      //     this.$nextTick(() => {
      //       this.doAddElementToCanvas();
      //     });
      //   }
      //   this.clearActiveCategory();
      // }
    },
    uploadVideo(file, trimPoints) {
      const uploadedFileTypeText = 'Video';
      const isSingleVideo = this.unUploadedVideos.length === 1;
      if (isSingleVideo) {
        this.isSaving = false;
        this.isDoneUploading = false;
        this.isUploadCancelled = false;
      } else {
        this.isVideoUploading = true;
      }
      this.uploadingVideos.push(file);


      // after all validations passed, continue uploading the file
      const apiEndpoint = process.env.VUE_APP_OFFEO_API;

      const additionalMetadata = {
        is_logo: false,
        project_folder: this.activeFolder,
        trim_points: trimPoints,
      };

      this.resumable = new ResumableJs({
        withCredentials: true,
        chunkSize: this.chunkSize,
        simultaneousUploads: this.simultaneousUploads,
        testChunks: false,
        throttleProgressCallbacks: 1,
        target: `${apiEndpoint}/v2/upload-file-chunks?timestamp=${_time(true)}`,
        headers: {
          'Upload-Metadata-Additional': JSON.stringify(additionalMetadata),
        },
      });

      if (!this.resumable.support) {
        this.showUpgradeButton = false;
        // eslint-disable-next-line
        this.alertFileError(errors.resumablejs_not_supported);
        return;
      }

      const fileName = file.name;

      if (this.isValidExtension(fileName) && !this.isFileSizeExceed(file.size)) {
        this.resumable.addFile(file);
      } else {
        this.alertFileError('Uploading failed, invalid file detected');
      }

      let progressIndex = 0;

      // Handle file add event
      this.resumable.on('fileAdded', (addedFile) => {
        progressIndex = 0;
        console.log('file added');
        const fileSkeleton = {
          id: addedFile.uniqueIdentifier,
          fileType: addedFile.file.type,
          fileName: addedFile.fileName,
          fileProgress: 0,
          isFailed: false,
          isPreparing: false,
        };
        this.$set(this.validFiles, addedFile.uniqueIdentifier, fileSkeleton);
        if (addedFile.file.type.indexOf('image') >= 0) {
          fileSkeleton.type = this.getActiveSubGroup === 'logos' ? 'logos' : 'images';
        }
        if (addedFile.file.type.indexOf('video') >= 0) fileSkeleton.type = 'videos';
        this.addSkeletonList(fileSkeleton);

        this.doStartChunkUpload();
      });

      this.resumable.on('fileError', (errorFile, res) => {
        progressIndex = 0;

        let error = {};
        try {
          error = JSON.parse(res);
        } catch (e) {
          error = res;
        }

        // this.doCancelChunkUpload();
        if (!this.isMobile) {
          this.alertFileError(`${uploadedFileTypeText} Upload Fail`, true);
          this.currentErrorVideoFile = [errorFile.file];
        } else {
          this.alertFileError(error.message);
        }

        // storage space limit
        if (error.file_limit === true) this.showUpgradeButton = error.show_upgrade_button;

        // storage space limit & invalid file error
        if (
          (typeof error.error_status !== 'undefined'
          && error.error_status === 'invalid-filetype')
          || error.file_limit === true
        ) {
          this.isDoneUploading = true;
          this.allowClose = true;
          this.deleteSkeletonList(file.uniqueIdentifier);
        }

        const newFileName = `${errorFile.fileName} - <span><i>${error.message}</i></span>`;
        if (this.validFiles[errorFile.uniqueIdentifier]) {
          this.$set(this.validFiles[errorFile.uniqueIdentifier], 'fileName', newFileName);
          this.$set(this.validFiles[errorFile.uniqueIdentifier], 'isFailed', true);
        }

        this.updateSkeletonList({
          id: errorFile.uniqueIdentifier,
          fileName: newFileName,
          isFailed: true,
        });

        this.setStorageSpaceLeft(error.space_left || 'unlimited');
        this.isUploadCancelled = false;
      });

      this.resumable.on('fileProgress', (progressingFile) => {
        const fileUID = progressingFile.uniqueIdentifier;
        // Handle progress for both the file and the overall upload
        // console.log('fileprogress');
        if (this.isUploadCancelled) {
          this.resumable.cancel();
        }
        const fileProgress = Math.floor(progressingFile.progress() * 100);

        this.validFiles[fileUID].fileProgress = fileProgress;
        this.updateSkeletonList({
          id: fileUID,
          fileProgress,
        });

        // console.log('fileProgress is', fileUID, progressIndex)
        if (progressIndex === 0) this.doLoadActiveGroup();
        progressIndex += 1;
      });

      this.resumable.on('fileSuccess', (succeededFile, res) => {
        // Reflect that the file upload has completed
        // eslint-disable-next-line
        res = JSON.parse(res);

        console.log('v fileSuccess', res, succeededFile.uniqueIdentifier);
        if (res.success) {
          if (this.chunkUploadPercentage >= 100) {
            if (isSingleVideo) {
              this.isSaving = false;
              // this.showUploadingModal = false;
              this.isDoneUploading = true;
              this.unUploadedVideos = [];

              this.alertUpload('success', `${uploadedFileTypeText} Uploaded`, 7000);
              // this.doLoadActiveGroup();
            } else {
              this.isVideoUploading = false;
            }
            this.removeUploadingVideo(this.findVideoIndex(this.uploadingVideos, file));
            if (this.isTrimmingVideosBeforeUpload) this.uploadedVideos.push(file);
            // this.createVideoTrimmerChannel();
            this.doClearFileInput();
            console.log('on file success', cloneDeep(this.skeletonList));
            /**
             * only delete the skeleton if the res returns with user_files
             * since this will show a CategoryItem on top of current SkeletonItem (will be duplicate)
             * res: {
             *   message: '',
             *   user_files: {}
             * }
             */
            if (res.user_files) {
              this.deleteSkeletonList(file.uniqueIdentifier);
            } else {
              this.updateSkeletonList({
                id: file.uniqueIdentifier,
                isPreparing: true,
              });
            }
          }
          this.setStorageSpaceLeft(res.space_left || 'unlimited');
        } else {
          // this.doCancelChunkUpload();
          // this.isUploadCancelled = true;
          this.$set(this.validFiles[succeededFile.uniqueIdentifier], 'isFailed', true);
          this.updateSkeletonList({
            id: succeededFile.uniqueIdentifier,
            isFailed: true,
          });

          if (!this.isMobile) {
            this.alertFileError(`${uploadedFileTypeText} Upload Fail`, true);
            this.currentErrorVideoFile = [succeededFile.file];
          } else {
            this.alertError('', res.message, 3000, 'TopCenterNotif', true);
          }
        }

        this.fileCompleted.push(succeededFile.fileName);
      });
      if (isSingleVideo) {
        this.resumable.on('cancel', () => {
          // this.doCancelChunkUpload();
          this.isUploadCancelled = true;
        });
        this.$nextTick(() => {
          // this.showUploadingModal = true;
        });
      }
    },
    findVideoIndex(arr, video) {
      return arr.findIndex(v => isEqualFile(v, video));
    },
    removeUploadingVideo(index) {
      this.uploadingVideos.splice(index, 1);
    },
    removeUploadedVideo(index) {
      this.uploadedVideos.splice(index, 1);
    },
    removeVideo(videoIndex) {
      const unUploadedVideo = this.unUploadedVideos[videoIndex];
      const uploadedVideoIndex = this.findVideoIndex(this.uploadedVideos, unUploadedVideo);
      if (uploadedVideoIndex > -1) {
        this.removeUploadedVideo(uploadedVideoIndex);
      }
      const uploadingVideoIndex = this.findVideoIndex(this.uploadingVideos, unUploadedVideo);
      if (uploadingVideoIndex > -1) {
        this.removeUploadedVideo(uploadingVideoIndex);
      }
      this.unUploadedVideos.splice(videoIndex, 1);
    },
    uploadTrimmedVideo(video, trimPoints) {
      this.previousVideoUploadTrimPoints = trimPoints;
      this.uploadVideo(video, trimPoints);
    },
    resetData() {
      // this.chunkUploadPercentage = 0;
      // this.showUploadingModal = false;
      this.isDoneUploading = false;
      this.isSaving = false;
      this.upload = null;
      this.isProcessing = false;
      this.dropFileName = '';
      this.setIsUploadingMusicFile(false);
    },
    stopTrimmingVideosBeforeUpload() {
      if (this.uploadedVideos && this.uploadedVideos.length) {
        this.doLoadActiveGroup();
      }
      this.setIsTrimmingVideosBeforeUpload(false);
      this.$nextTick(() => {
        this.unUploadedVideos = [];
        this.uploadingVideos = [];
        this.uploadedVideos = [];
        console.log('All video array cleared');
      });
    },
    mbToBytes(MB = 0) {
      return MB * (1024 * 1024);
    },
    showInvalidFileTypeError() {
      this.showUpgradeButton = false;
      this.alertFileError('Uploading failed, invalid file detected');
    },
    getFileTypeText(errorFile) {
      if (!errorFile) return '';

      const isImageFileUpload = errorFile.type.includes('image');
      const isAudioFileUpload = errorFile.type.includes('audio');
      const isVideoFileUpload = errorFile.type.includes('video');
      let errorFileTypeText = '';

      if (isImageFileUpload) errorFileTypeText = 'Image';
      if (isAudioFileUpload) errorFileTypeText = 'Music';
      if (isVideoFileUpload) errorFileTypeText = 'Video';

      return errorFileTypeText;
    },
    doReuploadFiles(fileType) {
      const isVideoReupload = fileType === 'Video';
      const isImageReupload = fileType === 'Image';
      const isMusicReupload = fileType === 'Music';

      if (this.currentErrorVideoFile.length && isVideoReupload) {
        this.uploadTrimmedVideo(this.currentErrorVideoFile[0], this.previousVideoUploadTrimPoints);
      }

      if (this.currentErrorFile.length && (isImageReupload || isMusicReupload)) {
        this.files.push(this.currentErrorFile[0]);
        this.doCheckFiles();
      }
    },
  },
};

export default uploadingMixin;
