<template lang="pug">
  .drop-zone(
    :style="dropZoneStyle"
    @dragleave="$emit('dropZoneDragLeave')"
    @dragover.prevent
    @drop.stop.prevent="dropFile"
  )
    DropHere(
      :title="title"
      :text="text"
    )
</template>

<script>
import DropHere from '@/components/UI/DropHere.vue';

export default {
  name: 'DropZone',
  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
    title: {
      type: String,
      default: 'Drop to upload your image here',
    },
    text: {
      type: String,
      default: 'You can add JPEG, JPG and PNG.',
    },
  },
  components: {
    DropHere,
  },
  computed: {
    dropZoneStyle() {
      return {
        width: this.width,
        height: this.height,
      };
    },
  },
  methods: {
    dropFile(e) {
      e.preventDefault();
      const { dataTransfer } = e;
      let droppedFile = null;
      if (dataTransfer && dataTransfer.items && dataTransfer.items.length) {
        droppedFile = dataTransfer.items[0].getAsFile();
      }
      this.$emit('dropZoneDrop', droppedFile);
      console.debug('event', e.dataTransfer.items[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
.drop-zone {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
  z-index: 99;
}
</style>
