<template lang="pug">
  .dropbox-selector(
    @click="doDropboxPicker()"
  )
    .select-files
      span.text(v-if="!onlyImage") Upload from Dropbox
      BaseButton.icon-dropbox(
        title="Upload from Dropbox"
        :is-icon="true"
        :plain-hover="true"
      )
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import Api from '@/services/api/Api';
import helperMixins from '@/components/mixins/helper-mixins';

export default {
  name: 'DropboxSelector',
  props: {
    onlyImage: { type: Boolean, default: false },
    isReplacingLogo: { type: Boolean, default: false },
    disallowVideo: { type: Boolean, default: false },
  },
  mixins: [helperMixins],
  methods: {
    ...mapMutations('assetsSidebar', ['setActiveCategory']),
    doDropboxPicker() {
      let extensions = [];
      let limit = 0;
      this.$emit('show-preloader');

      if (this.onlyImage) {
        // for smart create and storyboard
        extensions = ['.jpg', '.jpeg', '.mp4', '.png'];

        if (this.disallowVideo) {
          extensions = extensions.filter(item => item !== '.mp4');
        }
      } else if (this.isMasterDesigner) {
        extensions = ['.jpg', '.jpeg', '.mp4', '.mov', '.png', '.webm'];
      } else {
        extensions = ['.jpg', '.jpeg', '.mp4', '.mov', '.png'];
      }

      if (this.isFreeUser || !this.isLoggedIn) {
        limit = 104857600;
      } else {
        limit = 1073741824;
      }

      const vm = this;
      const options = {
        success(files) {
          console.log(files);

          if (!vm.isLoggedIn) {
            vm.alertError('', 'Uploading cancelled, login required.', 3000, 'TopCenterNotif', true);
          }
          vm.alertSuccess('', 'Importing dropbox file...', 5000, 'TopCenterNotif');
          const params = {
            imports: files[0],
            provider: 'dropbox',
            is_logo: vm.getActiveSubGroup === 'logos' || vm.isReplacingLogo,
          };
          Api.uploadFile(params)
            .then(() => {
              // console.log('File uploaded', response);
              vm.alertSuccess('', 'File has been uploaded', 3000, 'TopCenterNotif');
              vm.setActiveCategory({ id: 'all', slug: 'all' });
              vm.$emit('hide-preloader');
              vm.$emit('image-uploaded');
            })
            .catch((error) => {
              console.error(error);
              vm.$emit('hide-preloader');
            });
        },
        cancel() {
          vm.$emit('hide-preloader');
        },
        linkType: 'direct',
        multiselect: false,
        extensions,
        folderselect: false,
        sizeLimit: limit,
      };

      // eslint-disable-next-line
      Dropbox.choose(options);
    },
  },
  computed: {
    ...mapState(['isLoggedIn']),
    ...mapState('inspirationList', ['tmpUserId']),
    ...mapGetters('userData', ['isFreeUser', 'isMasterDesigner']),
    ...mapGetters('assetsSidebar', ['getActiveSubGroup']),
  },
  mounted() {
    const dropbox = document.createElement('script');
    dropbox.setAttribute('type', 'text/javascript');
    dropbox.setAttribute('src', 'https://www.dropbox.com/static/api/2/dropins.js');
    dropbox.setAttribute('id', 'dropboxjs');
    dropbox.setAttribute('data-app-key', '5h9isbgtsi6w2uf');
    document.head.appendChild(dropbox);
  },
};
</script>

<style lang="scss" scoped>
.dropbox-selector {
  width: 40px;
  height: 40px;
  background: none;
  border: 1px solid $light;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.2s ease-in-out, border-color 0.2s ease-in-out;

  .smart-create#app & {
    background-color: $light;
  }

  .icon-dropbox {
    width: 100%;
    height: 40px;
    color: $light;
    font-size: 16px;
    padding: 10px;

    &:hover {
      background: transparent !important;
    }
  }

  &:hover {
    background: $darkGrey5;
    border-color: $darkGrey5;
  }
}
</style>
