<template lang="pug">
  .template-masonry__item(
    :class="{ 'is-loading' : !imageIsLoaded }"
    @click="$emit('click', id)"
  )
    span.template-masonry__padding(
      :style="style"
    )
    img(
      :src="src"
      ref="image"
    )
</template>

<script>
export default {
  name: 'ReplaceImagesOverlayStockItem',
  props: {
    filteredImages: { type: Array, default: () => [] },
    src: { type: String, default: '' }, // this is the thumbnail
    id: { type: [String, Number], default: '' },
    thumbWidth: { type: Number, default: 100 },
    thumbHeight: { type: Number, default: 100 },
  },
  data() {
    return {
      ratio: 100,
      imageIsLoaded: false,
    };
  },
  computed: {
    style() {
      const styleObject = {};

      styleObject.paddingTop = `${this.ratio}%`;

      return styleObject;
    },
  },
  mounted() {
    const listElement = document.getElementsByClassName('replace-images--list')[0];
    const masonryElement = listElement.getElementsByClassName('template-masonry')[0];
    const columnElementWidth = masonryElement.children[0].clientWidth;
    this.imageWidth = columnElementWidth;
    this.removeWhitestripOnShutterstockImage();
  },
  methods: {
    removeWhitestripOnShutterstockImage() {
      // Set the width based on actual aspect ratio to remove Shutterstock whitestrip
      this.ratio = this.thumbHeight / this.thumbWidth * 100;
      this.imageIsLoaded = true;
    },
  },
};
</script>

<style lang="scss">
.template-masonry__item {
  position: relative;
  width: 100%;
  display: block;
  min-height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: $componentBorderRadius;
  box-shadow: 0 3px 4px rgba(10, 31, 68, 0.12), 0 0 1px rgba(10, 31, 68, 0.1);
  transition: box-shadow 0.25s ease-in-out, height 0.25s ease-in-out;
  cursor: pointer;

  &:hover {
    box-shadow: 0 26px 26px rgba(10, 31, 68, 0.12), 0 0 1px rgba(10, 31, 68, 0.1);
  }

  &.is-selected {
    border: 2px solid var(--blue700-green700);
  }

  &.is-loading {
    img {
      opacity: 0;
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0% 0%;
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
  }
}

.template-masonry__padding {
  display: block;
  width: 100%;
  height: 0;
  padding-top: 100%;
}
</style>
