<template lang="pug">
  .replace-image--container(
    :style="containerStyle"
    :draggable="isDraggable"
  )
    BaseCheckbox.replace-image__checkbox(
      v-if="isSelectable"
      :isChecked="isSelected"
      @change="toggleSelected"
    )
    Drag.replace-image__imgcontainer(
      @click.native="$emit('click', id)"
      @dragstart="$emit('is-dragging')"
      @dragend="$emit('stop-dragging')"
      :transfer-data="{ id }"
    )
      img.replace-image(
        :class="{ 'is-loaded': isLoaded, 'is-dark': isDarkMode }"
        :src="url"
        @load="isLoaded = true"
      )
      template(v-if="currentTab !== 'stocks' && !isTeamMember && !isTeamUpload")
        .replace-image__btns
          DeleteItem.replace-image__delete(
            :itemId="id"
            :is-temporary-file="isTemporaryFile"
            :is-smart-create="isSmartCreate"
            :is-team-brand-logo="isTeamBrandLogo"
          )
</template>

<script>
import { Drag } from 'vue-drag-drop';
import { mapState, mapGetters } from 'vuex';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';
import DeleteItem from '@/components/ProjectCanvas/AssetsSidebar/children/DeleteItem.vue';

export default {
  name: 'ReplaceImage',
  components: {
    Drag,
    DeleteItem,
  },
  mixins: [darkModeMixin],
  props: {
    id: {
      required: true,
    },
    url: {
      type: String,
      require: true,
    },
    currentTab: {
      type: String,
      require: true,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
    isSmartCreate: {
      type: Boolean,
      default: false,
    },
    isTeamBrandLogo: {
      type: Boolean,
      default: false,
    },
    isTeamUpload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  computed: {
    ...mapState(['isLoggedIn']),
    ...mapGetters('team', ['isTeamMember']),
    containerStyle() {
      return {
        width: this.width,
      };
    },
    isTemporaryFile() {
      return !this.isLoggedIn;
    },
  },
  methods: {
    toggleSelected() {
      console.log('toggleSelected', this.isSelected);
      if (this.isSelected) {
        this.$emit('deselect', this.id);
      } else {
        this.$emit('select', this.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.replace-image--container {
  cursor: pointer;
  display: inline-block;
  padding: 12px;
  position: relative;
  user-select: none;
  width: calc(100% / 8);

  @include mobile() {
    width: calc(100% / 3);
  };

  &:hover {
    .replace-image__btns {
      opacity: 1;
    }
  }

  .replace-logo__container & {
    width: calc(100% / 6);

    @include mobile() {
      width: calc(100% / 3);
    };
  }

  &.is-disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  &::before {
    content: '';
    position: relative;
    padding-top: 100%;
    display: block;
  }

  &:hover .replace-image {
    box-shadow: 0 26px 26px rgba(10, 31, 68, 0.12), 0 0 1px rgba(10, 31, 68, 0.1);
  }
}

.replace-image__imgcontainer {
  position: absolute;
  top: 12px;
  left: 12px;
  width: calc(100% - 24px);
  height: calc(100% - 24px);

  @include mobile() {
    height: calc(calc(100vw / 3) - 39px);
  }
}

.replace-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 3px 4px rgba(10, 31, 68, 0.12), 0 0 1px rgba(10, 31, 68, 0.1);
  border-radius: 4px;
  object-fit: contain;
  transition: box-shadow 0.25s ease-in-out;
  pointer-events: none;

  &.is-loaded {
    background: url('~@/assets/images/backgrounds/transparent.png');
  }

  &.is-dark,
  .mobile-project &.is-loaded {
    background: url('~@/assets/images/backgrounds/transparent-dark.jpg');
  }
}
</style>

<style lang="scss">
.replace-image__btns {
  font-weight: 500;
  position: absolute;
  height: 25%;
  width: 100%;
  background: linear-gradient(0deg, rgba(10, 31, 68, 0.7), rgba(10, 31, 68, 0));
  left: 0;
  bottom: 0px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  font-size: 1em;
  font-weight: 500;
  text-align: left;
  transition: opacity .25s ease-in-out;
  text-transform: initial;
  opacity: 0;

  .replace-image__delete {
    position: absolute;
    right: 0px;
    bottom: -3px;
    font-size: 1.25em;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    border-radius: $componentBorderRadius;

    .btn-delete {
      color: $light;
      padding: 0;
      transition: background 0.25s ease-in-out;
    }

    @include mobile() {
      display: none;
    }
  }
}

.replace-image__checkbox {
  position: absolute;
  top: 0;
  left: 0;

  &.checkbox .checkmark {
    left: 15px;
    top: 15px;
    z-index: 3;
    border-width: 2px !important;
  }
}
</style>
