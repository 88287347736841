import {
  mapGetters, mapState,
} from 'vuex';
import { isProduction } from '@/assets/scripts/variables';

/* eslint-disable */
const prodScriptsMixin = {
  computed: {
    ...mapState([
      'isLoggedIn',
    ]),
    ...mapState('userData', [
      'createdAt',
      'userLevel',
      'userRole',
      'name',
      'userId',
      'userEmail',
    ]),
    ...mapGetters('userData', [
      'isFreeUser',
      'isDesigner',
      'isAdmin',
    ]),
  },
  methods: {
    setExternalScripts() {
      if (!isProduction()) return;

      const root = document.getElementsByTagName('html')[0];

      if (process.env.VUE_APP_ENABLE_WISDOM === 'true') root.classList.add('enable-wisdom');

      if (process.env.VUE_APP_ENABLE_GTM === 'true') root.classList.add('enable-gtm');

      if (process.env.VUE_APP_ENABLE_TOOLTIP === 'true') root.classList.add('enable-tooltip');

      if (process.env.VUE_APP_ENABLE_HOTJAR === 'true') root.classList.add('enable-hotjar');

      const productionScripts = document.createElement('script');
      productionScripts.setAttribute('type', 'text/javascript');
      productionScripts.setAttribute('id', 'production-scripts');
      productionScripts.setAttribute('src', '/assets/scripts/production/scripts.js');
      document.head.appendChild(productionScripts);

      if (process.env.VUE_APP_ENABLE_SENTRY === 'true') {
        Sentry.init({
          dsn: process.env.VUE_APP_SENTRY_DNS,
          integrations: [new Integrations.Vue({ Vue, attachProps: true })],
        });
      }

      // Only need these integrations if not designer
      if (!this.isDesigner) {
        if (process.env.VUE_APP_ENABLE_WISDOM === 'true') {
          // Wisdom integration
          wisdom('identify', this.userId);
          wisdom('setUserInfo', {
            email: this.userEmail,
            firstName: this.name,
            traits: {
              user_type: this.userLevel,
              user_role: this.userRole,
              registration_date: this.createdAt,
            },
          });
        }

        if (process.env.VUE_APP_ENABLE_TOOLTIP === 'true') {
          // Tooltip integration
          window.TooltipUserData = {
            id: this.userId,
            name: this.name,
            email: this.userEmail,
            user_type: this.userLevel,
            user_role: this.userRole,
            registration_date: this.createdAt,
          };
        }

        if (typeof __insp !== 'undefined') {
          // Inspectlet integration
          __insp.push(['identify', userId]);
          __insp.push(['tagSession', 'direct']);
          __insp.push([
            'tagSession',
            {
              email: this.userEmail,
              userid: this.userId,
              name: this.name,
              user_type: this.userLevel,
              user_role: this.userRole,
              registration_date: this.createdAt,
            },
          ]);
        }
      }

      if (process.env.VUE_APP_ENABLE_SENTRY === 'true') {
        // Sentry integration
        Sentry.configureScope((scope) => {
          scope.setUser({ id: this.userId, username: this.username, email: this.userEmail });
        });
      }
    },
  },
};

export default prodScriptsMixin;
