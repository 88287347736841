import axios from 'axios';
import { serialize } from '@/assets/scripts/utilities';

// process.env.VUE_APP_OFFEO_API
// TODO?: fix when api routes merged to production
const offeoApi = process.env.VUE_APP_OFFEO_API;
const hasVersion = offeoApi.includes('/v1');
let url = '';

export default {
  brandfetch(params = {}, version = 'v1') {
    url = hasVersion
      ? '/branded-designs/color-logo'
      : `/${version}/branded-designs/color-logo`;

    return axios({
      method: 'post',
      url,
      data: params,
    });
  },
  retrieveBrandedStyles(version = 'v1') {
    url = hasVersion
      ? '/branded-styles'
      : `/${version}/branded-styles`;

    return axios({
      method: 'get',
      url,
    });
  },
  retrieveFontPairingCategories(version = 'v1') {
    url = hasVersion
      ? '/font-pairing-categories'
      : `/${version}/font-pairing-categories`;

    return axios({
      method: 'get',
      url,
    });
  },
  retrieveFontPairings(params = {}, version = 'v1') {
    url = hasVersion
      ? `/font-pairings?${serialize(params)}`
      : `/${version}/font-pairings?${serialize(params)}`;

    return axios({
      method: 'get',
      url,
    });
  },
  updateBrandStyle(params = {}, version = 'v1') {
    url = hasVersion
      ? '/collections/brands/update-brand-style'
      : `/${version}/collections/brands/update-brand-style`;
    return axios({
      method: 'post',
      url,
      data: params,
    });
  },
  addBrandLogo(file, version = 'v2') {
    const dataLogo = new FormData();
    dataLogo.append('file', file);
    dataLogo.append('provider', 'local');

    return axios({
      method: 'post',
      url: `/${version}/upload-file`,
      data: dataLogo,
      headers: {
        'Content-Type': 'multipart/form-data',
        'upload-metadata-additional': JSON.stringify({ is_logo: true }),
      },
    });
  },
};
