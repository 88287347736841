<template lang="pug">
  div(
    :class="[getActiveGroup, {'is-logo': !isNotLogo}]"
  )
    button.btn-delete(
      :class="extraClass"
      :title="title"
      :itemId="itemId"
      :disabled="!itemId"
      @click.prevent="_promptConfirmDeleteUserFile()"
    ) {{ text }}
      i.icon(
        :class="deleteIconClass"
        v-if="showIcon"
      )
</template>

<script>
import {
  mapGetters, mapActions,
} from 'vuex';
import helperMixins from '@/components/mixins/helper-mixins';

export default {
  mixins: [
    helperMixins,
  ],
  props: {
    itemId: { type: [String, Number], default: 0 },
    text: { type: String, default: '' },
    isFolder: { type: Boolean, default: false },
    isBrand: { type: Boolean, default: false },
    showIcon: { type: Boolean, default: true },
    extraClass: { type: String, default: '' },
    isTemporaryFile: { type: Boolean, default: false },
    icon: { type: String, default: '' },
    title: { type: String, default: 'Delete' },
    isNotLogo: { type: Boolean, default: true },
    isTeamBrandLogo: { type: Boolean, default: false },
    isSmartCreate: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters('assetsSidebar', [
      'getActiveGroup',
    ]),
    deleteIconClass() {
      return `icon-${this.icon !== '' ? this.icon : 'delete'}`;
    },
  },
  methods: {
    ...mapActions('assetsSidebar', [
      'promptConfirmDeleteUserFile',
    ]),
    // eslint-disable-next-line
    _promptConfirmDeleteUserFile() {
      this.promptConfirmDeleteUserFile({
        itemId: this.itemId,
        isFolder: this.isFolder,
        isBrand: this.isBrand,
        isTemporaryFile: this.isTemporaryFile,
        isTeamBrandLogo: this.isTeamBrandLogo,
        isSmartCreate: this.isSmartCreate,
      });
    },
  },
};
</script>
