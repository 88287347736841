<template lang="pug">
#remove-bg-option-wrapper
  CanvasModal.remove-bg-option__modal(
    :class="{ 'is-shaken': isShaken }"
    title="Remove Image Background Automatically"
    :isShow="true"
    @closeOverlay="closeModal"
    :titleHasBackButton="!isLoading"
    :hideCloseButton="true"
    @back="$emit('back')"
  )
    .create-option__list
      BasePreloader(v-if="isLoading")
      template(v-else)
        .create-option__box
          .create-option__item(
            @click="$emit('confirm', true)"
          )
            img(src="@/assets/images/smart-create/remove.gif")
            h2.create-option__title Remove Image Background
            p For best-integrated results with our designs
        .create-option__box
          .create-option__item(
            @click="$emit('confirm', false)"
          )
            img(src="@/assets/images/smart-create/keep.png")
            h2.create-option__title Keep Image Background
            p Suitable for images with complex background
</template>

<script>
export default {
  name: 'RemoveBgOption',
  data() {
    return {
      isShaken: false,
    };
  },
  props: {
    isLoading: { type: Boolean, default: false },
  },
  methods: {
    closeModal() {
      this.isShaken = true;
      setTimeout(() => {
        this.isShaken = false;
      }, 500);
    },
  },
  watch: {
    isLoading: {
      handler(val) {
        console.log('val', val);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.remove-bg-option__modal {
  .canvas-modal__header {
    background: var(--blue700-darkgrey900) !important;
    color: $white;

    .title {
    color: $white;

    }
  }

  .canvas-modal__content {
    background: var(--white-darkgrey700);

    .content {
      background: var(--white-darkgrey700);
      min-height: 500px;
    }
  }

  * {
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    ::-webkit-scrollbar-track {
      background-color: rgba($darkGrey600, 0);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--lightgrey500-black800);
      transition: background 0.25s ease-in-out;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--lightgrey500-black800);
    }
  }
}

// including it here because CreateOption is not included in canvas
.create-option__list {
  margin: 40px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;

  .preloader {
    min-height: 368px;
  }
}

.create-option__item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 250px;
  height: 325px;
  border-radius: $componentBorderRadius;
  border: 1px solid $defaultBorder;
  padding: 35px;
  box-shadow: 0px 3px 4px rgba(10, 31, 68, 0.1), 0px 0px 1px rgba(10, 31, 68, 0.08);
  transition: box-shadow 0.25s ease-in-out;

  &:hover {
    box-shadow: 0px 26px 26px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
    border-color: var(--blue700-green700);
  }

  p {
    color: var(--lightgrey900-lightgrey300);
    font-size: 0.875em;
    margin: 0;
  }

  @include mobile() {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    padding: 20px;
  }
}
</style>

<style lang="scss" scoped>
.create-option__list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0 60px;

  @include mobile() {
    margin-top: 0;
  };

  .create-option__box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include mobile() {
      margin: 5px 0;
    };

    .create-option__item {
      margin-bottom: 30px;
      min-width: 340px;
      cursor: pointer;
      height: auto;
      margin: 0 15px;
      padding: 50px 30px;

      @include mobile() {
        padding: 20px;
      };

      img{
        width: 150px;
        margin-bottom: 20px;
      }
    }
  }
}

.create-option__title{
  max-width: 210px;
  display: block;
  margin: auto auto 10px;
  color: var(--darkgrey800-lightgrey400);
}
</style>
