<template lang="pug">
BaseDialog.uploading-modal(
  isShow=true
  :isDanger="!isDone"
  :isSuccess="isDone"
  :isHollow="isProcessing"
  :text="text"
  @confirm="confirmOrCancelUpload"
  @closeOverlay="confirmOrCancelUpload"
  :button1Text="buttonText"
  noCancel=true
  :noButton="noButton"
)
  BasePreloader(v-if="isSaving")

  template(v-if="(!isSaving && isCancelling)")
    BaseDialog.cancel-uploading-modal(
      isShow=true
      isDanger=true
      text="Are you sure you want to cancel the upload?"
      @confirm="confirmUploadCancel"
      @closeOverlay="cancelUploadCancel"
    )
</template>

<script>
export default {
  name: 'UploadingModal',
  data() {
    return {
      noButton: false,
      isCancelling: false,
    };
  },
  props: {
    text: { type: String, default: '' },
    isSaving: { type: Boolean, default: false },
    isDone: { type: Boolean, default: false },
    isProcessing: { type: Boolean, default: false },
  },
  computed: {
    buttonText() {
      if (!this.isDone && !this.isProcessing) return 'Cancel upload';
      return 'Close';
    },
  },
  methods: {
    confirmOrCancelUpload() {
      if (this.isDone) this.$emit('confirm');
      else if (!this.isSaving) {
        this.isCancelling = true;
        this.$emit('pause-upload');
      }
    },
    confirmUploadCancel() {
      this.$emit('cancel-upload');
    },
    cancelUploadCancel() {
      this.isCancelling = false;
      this.$emit('resume-upload');
    },
  },
  watch: {
    isSaving(val) {
      if (val) this.noButton = true;
      else this.noButton = false;
    },
  },
};
</script>

<style lang="scss">
.uploading-modal {
  .dialog-modal__content {
    max-width: 420px;
  }

  .preloader {
    width: 40px;
    height: 40px;
    margin: -4px auto;
  }
}
</style>
