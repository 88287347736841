<template lang="pug">
button.replace-tab(
  type='button'
  :class="{ 'is-active': activeTab === value }"
  @click="$emit('changeTab', value)"
)
  span(v-html="name")
</template>

<script>
export default {
  name: 'ReplaceImagesOverlayTab',
  data() {
    return {
      showOptimized: false,
    };
  },
  props: {
    name: { type: String, default: '' },
    icon: { type: String, default: '' },
    value: { type: String, default: '' },
    optimized: { type: Array, default: () => [] },
    activeTab: { type: String, default: '' },
  },
};
</script>

<style scoped lang="scss">
.replace-tab {
  background: var(--lightgrey100-black700);
  color: var(--black-white);
  cursor: pointer;
  line-height: 1;
  min-height: 32px;
  padding: 18px 26px;
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid transparent;
  font-weight: 500;
  font-size: 0.875em;;
  text-transform: capitalize;
  align-items: center;
  justify-content: center;
  opacity: 0.4;
  transition: opacity 0.15s ease-in-out;

  @include mobile() {
    padding: 18px 12px;
  };

  &:hover {
    opacity: 1;
    transition: opacity 0.15s ease-in-out;
  }

  &.is-active {
    opacity: 1;
    transition: opacity 0.15s ease-in-out;
    cursor: context-menu;
    border-bottom: 2px solid var(--blue700-green700);
  }
}

.optimized-list {
  position: absolute;
  width: 100%;
  border-radius: 4px;
  background: $lightBg;
  padding-top: 5px;
  visibility: hidden;
  opacity: 0;
  transform: translateY(20px);
  transition: visibility .25s ease-in-out,
              opacity .25s ease-in-out,
              transform .25s ease-in-out;

  &.is-show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}

.optimized-list__title {
  display: block;
  font-size: 0.75em;
  text-transform: uppercase;
  color: #8A94A6;
  font-weight: 600;
  margin: 0 0 5px;
}

.optimized-list__list {
  margin: 0;
  padding: 0;
}

.optimized-list__item {
  border-top: 1px solid $borderGrey;
  font-size: 0.875em;
  color: #53627C;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;

  .icon {
    color: #27AE60;
    width: 20px;
    height: 20px;
    font-size: 20px;
    margin-right: 16px;
  }
}
</style>
