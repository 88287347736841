<template lang="pug">
.replace-images--overlay
  CanvasModal.smart-create-image__modal(
    :class="{ 'is-dark': isDarkMode }"
    :title="title"
    :subtitle="subtitle"
    :isShow="isShow"
    @closeOverlay="closeOverlay"
    :extraClass="extraClass"
    :showMainAreaOverlay="showMainAreaOverlay"
    @closeMainAreaOverlay="showMainAreaOverlay = false"
    @click.native="modalClickHandler"
  )
    template(#top)
      template(v-if="!isMobileVersion")
        ReplaceImagesOverlayTab(
          v-for="category in filteredCategories"
          :key="category.value"
          :name="category.name"
          :value="category.value"
          :activeTab="currentTab"
          @changeTab="changeTab"
        )
        BaseButton.lite-version-upload-button(
          v-if="isLiteVersion && currentTab !== 'user-files'"
          :is-canvas="true"
          :is-primary-hollow="true"
          @click="onClickLiteUpload"
        )
          i.icon.icon-plus
          span.label Upload
    BasePreloader(
      v-if="isLoading"
    )
    template(v-else)
      //--------------
      //- top bar for search and upload
      //--------------
      .tools-bar(v-if="!isMobileVersion")
        ReplaceImagesSearch(
          v-show="currentTab === 'stocks'"
          @search="search"
          @clear-search="clearSearch"
          :currentTab="currentTab"
          ref="search"
        )
        ReplaceImagesSearchURL(
          v-if="!showEmptyList"
          v-show="currentTab === 'url'"
          @search="search"
          @clear-search="clearSearch"
          :currentTab="currentTab"
          ref="search"
          :keyword="typeof keyword === 'object' ? keyword : {}"
        )
        .file-upload-container(
          v-show="currentTab === 'user-files' && !isTeamUpload"
        )
          .upload-buttons(
            :class="{ 'has-create-folder': isLoggedIn && currentTab === 'user-files' && activeImageFolder === 0 && filteredImages.length }"
          )
            button.btn-create-folder.btn-myfile-action(
              v-if="showCreateFolder"
              type="button"
              :class="{ 'is-loading': isCreatingFolder }"
              :disabled="isCreatingFolder"
              @click="createFolder"
              :title="createFolderText"
            )
              i.icon.icon-add-folder
              span.text Create Folder
            label.btn-local.btn(
              :class="{ 'is-dark': isDarkMode }"
              @drop.prevent="uploadingFiles('drop', $event)"
              @dragover.prevent
            )
              input(
                type="file"
                id="manual-file-upload"
                ref="fileUpload"
                :accept="mobileFileAccept"
                @change="uploadingFiles('upload')"
              )
              i.icon.icon-plus
              span.label Upload
              .tooltip-wrapper
                i.icon.icon-info
                .tooltip
                  div Supports only
                  .file-format {{ allowedFormat }}
                  //- TODO: update with user actual limit
                  div
                    strong You have {{ storageSpaceLeft }} left
            //- Temporarily hide google drive button
            //- .btn-google-drive.btn
            //-   GoogleDriveSelector
            .btn-dropbox.btn(
              v-if="!isLiteVersion"
            )
              DropboxSelector(
                :onlyImage="true"
                :disallowVideo="true"
                @show-preloader="showPreloader = true"
                @hide-preloader="showPreloader = false"
                @image-uploaded="dropboxUploadSuccess"
              )
          .tools-bar__mobile(
            :class="{ 'full-width': isMultiSelecting }"
          )
            template(v-if="isMultiSelecting")
              button.btn-move-file(
                v-if="showMove"
                type="button"
                @click="moveFileMobileEvent"
              ) {{ moveFileText }}
              button.btn-move-file-out(
                v-if="showMoveOut"
                type="button"
                @click="moveFileOutMobileEvent"
              ) Move out
              button.btn-delete-file(
                v-if="multiSelectedFiles.length && !isMovingMultiSelectedFiles"
                type="button"
                @click="deleteFile"
              ) Delete
              button.btn-cancel-multiselect(
                type="button"
                @click="cancelEvent"
              ) {{ cancelText }}
            button.btn-select-files(
              v-if="!isMultiSelecting && showListing"
              type="button"
              @click="isMultiSelecting = true"
            ) Select
          transition(
            name="slide-mobile-upload"
          )
            .upload-files__mobile(
              v-if="showFileUploadOption && !isMobileVersion"
              v-click-outside="onClickOutsideFileUploadOptions"
            )
              span.upload-files__name Upload
              .btn-upload-image-phone
                input.upload-files__input(
                  type="file"
                  id="mobile-file-upload"
                  ref="mobileFileUpload"
                  :accept="mobileFileAccept"
                  @change="doSelectFile()"
                )
                span.text Upload image from phone
                i.icon.icon-plus
              DropboxSelector.btn-upload-image-dropbox(
                @show-preloader="showPreloader = true"
                @hide-preloader="showPreloader = false; mobileHideFileUpload"
                @image-uploaded="dropboxUploadSuccess"
              )
      button.btn-upload-mobile(
        v-if="currentTab === 'user-files' && !isMobileVersion"
        type="button"
        @click="mobileShowFileUpload"
      )
        i.icon.icon-plus
      //--------------
      //- back button
      //--------------
      Drop.drop-main-folder(
        v-if="!isMultiSelecting"
        :class="{ 'is-hovered': isMainFolderHovered }"
        @drop="dropFilesToMainFolder"
        @dragover="isMainFolderHovered = true; isDropping = true"
        @dragleave="isMainFolderHovered = false; isDropping = false"
      )
        BaseButton.btn-reset-images(
          v-if="currentTab === 'user-files' && activeImageFolder > 0"
          :is-text="true"
          :is-canvas="true"
          :plain-hover="true"
          icon-before="icon-left-arrow-long"
          @click="resetActiveFolder"
        ) Back
      //--------------
      //- image list
      //--------------
      .replace-images--list(
        @dragenter="dragEnter"
        @dragexit="dragLeave"
      )
        .upload-files__mobile-smart-create(
          v-if="isMobileVersion && activeImageFolder === 0 && currentTab === 'user-files'"
        )
          .btn-upload-image-phone
            input.upload-files__input(
              type="file"
              id="mobile-file-upload"
              ref="mobileFileUpload"
              :accept="mobileFileAccept"
              @change="doSelectFile()"
            )
            i.icon.icon-plus
        //--------------
        //- for dragging files from outside of app
        //--------------
        .drop-zone-container
          DropZone(
            v-if="showDropZone"
            @dropZoneDrop="drop"
            @dropZoneDragLeave="dragLeave"
          )

        //--------------
        //- when it's empty
        //--------------
        .empty-team-uploads__list(v-if="showEmptyList && teamId && currentTab === 'user-files'")
          h4.text No files found.

        .empty-list(
          v-if="showEmptyList && (!teamId || teamId && currentTab !== 'user-files')"
          :class="{ 'is-url': currentTab === 'url', 'inside-folder': activeImageFolder > 0, 'is-dark': isDarkMode }"
          @click="showFileUpload"
        )
          template(v-if="currentTab === 'url'")
            i.icon.icon-download1
          template(v-else)
            i.icon.icon-upload1
          .title {{ emptyListTitle }}
          .text {{ emptyListText }}
          ReplaceImagesSearchURL(
            v-show="currentTab === 'url'"
            @search="search"
            @clear-search="clearSearch"
            :currentTab="currentTab"
            ref="search"
            :keyword="typeof keyword === 'object' ? keyword : {}"
          )
        p.replace-images__text(
          v-if="currentTab === 'stocks' && !filteredImages.length && !isFetchable"
        ) No images found.

        template(
          v-if="currentTab === 'user-files' && activeImageFolder > 0"
        )
          .folder-title(:class="{ 'is-team-upload': isTeamUpload }")
            span.folder-title__name {{ activeImageFolderName }}
            button.btn-edit-folder(
              v-if="!isTeamUpload"
              type="button"
              @click="setFolderOption(true)"
            )
              i.icon.icon-options-line

          .folder-options(
            v-if="showFolderOption && !isTeamUpload"
            v-click-outside="onClickOutsideFolderOptions"
          )
            input.folder-options__name(
              autocomplete="off"
              type="text"
              :readonly="isFolderNameReadOnly"
              ref="folderName"
              v-model="modelFolderName"
              @blur="confirmFolderName"
              @keyup.enter.prevent="confirmFolderName"
            )
            button.btn-rename-folder(
              type="button"
              @click="editFolderName"
            )
              span.text Rename Folder
              i.icon.icon-edit
            button.btn-delete-folder(
              type="button"
              @click="deleteFolder"
            )
              span.text Delete Folder
              i.icon.icon-delete

        //--------------
        //- images
        //--------------
        template(v-if="showListing")
          //--------------
          //- stocks list with masonry
          //--------------
          masonry.template-masonry(
            v-if="currentTab === 'stocks' || currentTab === 'url'"
            :cols="{default: 8, 600: 3}"
            :gutter="20"
          )
            ReplaceImagesOverlayStockItem(
              v-for="image in filteredImages"
              :key="`replace-image-${image.id}`"
              :src="getThumbUrl(image)"
              :id="image.id"
              :thumbWidth="image.thumb_width"
              :thumbHeight="image.thumb_height"
              :class="{'is-selected': image.id === tmpSelectedImageId}"
              @click="selectImage"
            )
          //--------------
          //- my files
          //--------------
          template(
            v-else-if="currentTab === 'user-files'"
          )
            .replace-image--container.user-file-skeleton-preloader(
              v-for="skeleton in skeletonList"
              :class="{ 'is-dark': isDarkMode }"
            )
              BasePreloader(
                :text="skeleton.fileName || ''"
              )

            //--------------
            //- folders
            //--------------
            template(v-if="teamId || (!teamId && !activeImageFolder)")
              ReplaceImageFolder(
                :class="{ 'is-moving-here': folder.id === isMovingToFolder }"
                v-for="folder in filteredFolders"
                :key="`folder-${folder.id}`"
                :id="folder.id"
                :name="folder.folder_name"
                :is-team-upload="isTeamUpload"
                @click="folderClickEvent"
                @drop="dropFilesToFolder"
                @is-dropping="isDropping = true"
                @stop-dropping="isDropping = false"
              )

            //--------------
            //- images
            //--------------
            ReplaceImage(
              v-for="image in filteredImages"
              :key="`replace-image-${image.id}`"
              :id="image.id"
              :url="getThumbUrl(image)"
              @click="selectImage(image.id)"
              :class="{'is-selected': image.id === tmpSelectedImageId, 'is-disabled': isMovingMultiSelectedFiles}"
              :currentTab="currentTab"
              :isDraggable="true"
              :isSelected="multiSelectedFiles.indexOf(image.id) > -1"
              :isSelectable="isMultiSelecting"
              :is-team-upload="isTeamUpload"
              @is-dragging="isDraggingUserFile = true"
              @stop-dragging="isDraggingUserFile = false"
              @select="addMultiSelectedFiles"
              @deselect="removeMultiSelectedFiles"
            )

          template(
            v-else
          )
            .replace-images__sample-image(
              :class="{ 'is-selected': selectedPreviewSampleIndex === index }"
              v-for="(path, index) in previewAssets"
              @click="onSampleClick(index)"
            )
              img(
                :src="getImageUrlFromSample(path)"
              )
        BasePreloader(
          v-if="isFetchable || showPreloader"
          :type=12
        )
    template(#bottom)
      BaseButton.use-image__button(
        v-if="!isMobileVersion"
        is-primary=true
        :is-canvas="true"
        is-tall=true
        :isDisabled="!tmpSelectedImageId && !selectedImageUrl"
        @click="confirmImage"
      ) Use this image

  UploadingModal(
    v-if="showUploadingModal"
    :isDone="isDoneUploading"
    :isSaving="isSaving"
    :isProcessing="isProcessing"
    :text="uploadingText"
    @confirm="beforeConfirmUploadingOverlay"
    @pause-upload="doPauseChunkUpload"
    @resume-upload="doStartChunkUpload"
    @cancel-upload="cancelUpload"
  )
  BaseDialog.file-validation-modal(
    v-if="isFileError"
    isShow=true
    isSuccess=true
    :text="fileErrorMessage"
    noCancel=true
    button1Text="Close"
    :noUpgrade="!showUpgradeButton"
    @confirm="isFileError = false"
    @closeOverlay="isFileError = false"
  )
</template>

<script>
import {
  mapGetters, mapMutations, mapState, mapActions,
} from 'vuex';
import scrollMonitor from 'scrollmonitor';

import vClickOutside from 'v-click-outside';
import { Drop } from 'vue-drag-drop';
import BasePreloader from '../../UI/BasePreloader.vue';
import DropboxSelector from '../../ProjectCanvas/AssetsSidebar/DropboxSelector.vue';
import ReplaceImage from './children/ReplaceImage.vue';
import ReplaceImageFolder from './children/ReplaceImageFolder.vue';
import ReplaceImagesOverlayTab from './children/ReplaceImagesOverlayTab.vue';
import ReplaceImagesSearch from './children/ReplaceImagesSearch.vue';
import ReplaceImagesSearchURL from './children/ReplaceImagesSearchURL.vue';
import ReplaceImagesOverlayStockItem from './children/ReplaceImagesOverlayStockItem.vue';
import UploadingModal from '@/components/ProjectCanvas/PageOverlay/UploadingModal.vue';
import DropZone from '@/components/SmartCreate/PageOverlay/children/DropZone.vue';
import PreviewAssets from '@/assets/data/preview-assets.json';

import { isMobile, isEmpty, isURL } from '@/assets/scripts/utilities';
import CollectionsApi from '@/services/api/CollectionsApi';
import Api from '@/services/api/Api';
import UserApi from '@/services/api/UserApi';
import uploadingMixin from '../../mixins/uploading-mixins';
import helperMixins from '@/components/mixins/helper-mixins';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

const PER_PAGE = 30;

export default {
  name: 'ReplaceImagesOverlay',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Add Image',
    },
    subtitle: {
      type: String,
      default: 'Choose the image that best represents your project. You can add more images later.',
    },
    preSelectedTab: {
      type: String,
      default: 'user-files',
    },
    isLiteVersion: {
      type: Boolean,
      default: false,
    },
    isMobileVersion: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [uploadingMixin, helperMixins, darkModeMixin],
  components: {
    Drop,
    DropZone,
    BasePreloader,
    DropboxSelector,
    ReplaceImage,
    ReplaceImageFolder,
    ReplaceImagesOverlayTab,
    ReplaceImagesSearch,
    ReplaceImagesSearchURL,
    UploadingModal,
    ReplaceImagesOverlayStockItem,
  },
  data() {
    return {
      images: [],
      folders: [],
      currentPage: 0,
      currentTab: 'user-files',
      keyword: 'portrait isolated',
      totalPages: null,
      activeImageFolder: 0,
      tmpSelectedImageId: '',
      categories: [
        {
          name: 'My Files',
          value: 'user-files',
        },
        // {
        //   name: 'Stock Images',
        //   value: 'stocks',
        // },
        {
          name: 'Sample Images',
          value: 'sample',
        },
        {
          name: 'From URL',
          value: 'url',
        },
      ],
      isDragEnter: false,
      showPreloader: false,
      isCreatingFolder: false,
      isUploadingFile: false,
      isDraggingUserFile: false,
      isMainFolderHovered: false,
      previewAssets: [],
      // when selectedPreviewSampleIndex is not -1,
      // use the previewAssets
      selectedPreviewSampleIndex: -1,
      selectedImageUrl: '',
      multiSelectedFiles: [],
      isMultiSelecting: false,
      isMovingMultiSelectedFiles: false,
      isMovingToFolder: 0,
      isFolderNameReadOnly: true,
      showFolderOption: false,
      showFileUploadOption: false,
      isDeletingFolder: false,
      isMobile: isMobile(),
      showMainAreaOverlay: false,
      isExiting: false,
      isUrlTabFirstLoad: false,

      scrollWatchTimeout: null,
    };
  },
  computed: {
    ...mapState(['isLoggedIn']),
    ...mapState('assetsSidebar', ['templatePacks', 'activeDesignFormat']),
    ...mapState('team', ['teamId']),
    ...mapGetters(['isLiteMode']),
    ...mapGetters('assetsSidebar', [
      'getSidebarElements',
      'getActiveGroup',
      'getActiveSubGroup',
      'getTemplatePacks',
      'getStockEndpointVersion',
    ]),
    ...mapGetters('team', ['isInTeamWorkspace']),
    extraClass() {
      const cssClass = ['canvas-modal--design'];

      if (this.isMobile) cssClass.push('is-slide-up');
      if (this.isExiting) cssClass.push('is-exiting');

      return cssClass.join(' ');
    },
    showDropZone() {
      // for lite mode, allow user to drop at any tabs before changing to user-files
      return (
        (this.currentTab === 'user-files' || this.isLiteMode)
        && this.isDragEnter
        && !this.isDraggingUserFile
      );
    },
    showEmptyList() {
      return (
        (this.currentTab === 'user-files' || this.currentTab === 'url')
        && !this.hasUploadList
        && this.totalPages !== null
        && !this.isFetchable
        && !this.showPreloader
      );
    },
    hasUploadList() {
      return this.teamId || (!this.teamId && !this.activeImageFolder)
        ? !!(this.filteredFolders.length || this.filteredImages.length)
        : !!this.filteredImages.length;
    },
    isFetchable() {
      return this.currentPage !== this.totalPages;
    },
    isTeamUpload() {
      if (this.teamId && this.currentTab === 'user-files') {
        return true;
      }
      return false;
    },
    filteredCategories() {
      if (this.isLiteVersion) {
        return this.categories.filter(category => category.value !== 'url');
      }
      return this.categories;
    },
    filteredImages() {
      // need to make sure it's not showing on wrong tab when the files halfway fetching
      const filteredImages = this.images;

      if (this.currentTab === 'stocks' || this.currentTab === 'url') {
        // stocks won't have user id
        return filteredImages.filter(image => !image.user_id);
      }

      if (this.currentTab === 'sample') {
        return this.previewAssets;
      }

      // if the active
      if (this.activeImageFolder === 0) return filteredImages.filter(image => !image.folder_id && image.user_id);

      // use == because it may be comparing string vs int
      return filteredImages.filter(
        image => image.folder_id === this.activeImageFolder && image.user_id,
      );
    },
    filteredFolders() {
      let folderList = this.folders;

      if (this.teamId) {
        const activeFolderId = this.activeImageFolder || null;
        folderList = folderList.filter(folder => folder.parent_id === activeFolderId);
      }

      return folderList;
    },
    createFolderText() {
      return this.isCreatingFolder ? 'Creating...' : 'Create Folder';
    },
    showListing() {
      if (this.currentTab === 'sample') return true;

      if (this.currentTab === 'user-files') {
        return this.hasUploadList;
      }
      return this.filteredImages.length && !this.showPreloader;
    },
    showCreateFolder() {
      return (
        this.isLoggedIn
        && this.currentTab === 'user-files'
        && this.activeImageFolder === 0
        && this.filteredImages.length
        && !this.isMultiSelecting
      );
    },
    showMove() {
      if (this.isMovingMultiSelectedFiles) return this.isMovingToFolder > 0;
      return !!this.multiSelectedFiles.length && this.activeImageFolder === 0;
    },
    showMoveOut() {
      return this.multiSelectedFiles.length && this.activeImageFolder !== 0;
    },
    moveFileText() {
      if (this.isMovingMultiSelectedFiles) return 'Move to folder';
      return 'Move';
    },
    cancelText() {
      if (this.isMovingMultiSelectedFiles) return 'Cancel move';
      return 'Cancel';
    },
    activeImageFolderName() {
      if (this.activeImageFolder === 0) return '';
      return this.folders.find(folder => folder.id === this.activeImageFolder).folder_name;
    },
    modelFolderName: {
      get() {
        return this.activeImageFolderName;
      },
      set() {
        // do nothing until blur
      },
    },
    emptyListTitle() {
      if (this.isMobile) return this.currentTab === 'url' ? 'Download image' : 'Upload image';
      return this.currentTab === 'url'
        ? 'Download images from URL'
        : "You haven't uploaded any files yet";
    },
    emptyListText() {
      if (this.isMobile) {
        return this.currentTab === 'url'
          ? 'Download images from any website, webpage via url or link'
          : 'Tap here to upload files here. You can add JPEG, JPG and PNG.';
      }
      return this.currentTab === 'url'
        ? 'Download images from any website, webpage via url or link'
        : 'Drag and drop files here. You can add JPEG, JPG and PNG.';
    },
  },
  methods: {
    ...mapMutations(['setShowSidebar', 'setIsModalVisible']),
    ...mapMutations('inspirationList', ['setShowRemoveBgOption', 'setIsTeamFileUpload']),
    ...mapMutations('assetsSidebar', ['setShutterstockSearchId', 'setActiveFolder']),
    ...mapActions('assetsSidebar', ['promptConfirmDeleteUserFile']),
    addScrollWatch(reset) {
      console.log('addScrollWatch', reset, this.isFetchable);
      // infinite scrolling
      if (!this.isFetchable) {
        return;
      }

      const containerElement = document.querySelector('.replace-images--list');
      // console.debug('containerElement', containerElement);
      if (containerElement) {
        const containerMonitor = scrollMonitor.createContainer(containerElement);
        const preloader = containerElement.querySelector('.preloader');
        const elementWatcher = containerMonitor.create(preloader);
        // console.debug('elementWatcher', elementWatcher);

        if (this.currentTab === 'stocks') {
          // set timeout so that it doesn't call nonstop
          // destroy used watcher in order to use new watcher
          this.scrollWatchTimeout = setTimeout(() => {
            console.debug('scrollWatchTimeout');
            elementWatcher.enterViewport(() => {
              console.log('enterViewport');
              this.getImages();
              elementWatcher.destroy();
            });
            elementWatcher.exitViewport(() => {
              console.log('exitViewport');
              this.getImages();
              elementWatcher.destroy();
            });
            clearTimeout(this.scrollWatchTimeout);
          }, 500);
        } else {
          if (elementWatcher) elementWatcher.destroy();
          this.getImages(reset);
          clearTimeout(this.scrollWatchTimeout);
        }
      }
    },
    beforeConfirmUploadingOverlay() {
      if (this.isProcessing || this.isDoneUploading) {
        this.changeTab('user-files');
      }
      this.confirmUploadingOverlay();
    },
    closeOverlay() {
      if (this.isMobile) {
        this.isExiting = true;
        setTimeout(() => {
          this.setIsModalVisible(false);
          this.$emit('closeOverlay');
        }, 300);
      } else {
        this.setIsModalVisible(false);
        this.$emit('closeOverlay');
      }
    },
    getImages(reset) {
      if (this.isUploadingFile) return;

      console.log('getImages', reset, this.isLoggedIn, this.currentTab);

      const endpoint = this.teamId && this.currentTab === 'user-files' ? 'team-files' : this.currentTab;
      const params = {};

      if (reset) {
        this.images = [];
        this.currentPage = 0;
      }

      if (endpoint === 'sample') {
        this.resetPages(true);
        return;
      }

      if (endpoint === 'stocks') {
        params.item_per_page = PER_PAGE;
        params.type = 'images';
        this.currentPage += 1;
        params.page = this.currentPage;
        params.search = this.keyword ? this.keyword : 'portrait isolated';
      } else if (endpoint === 'team-files') {
        params.team_id = this.teamId;
        params.menu = 'images';

        if (this.activeImageFolder > 0) params.folder_id = this.activeImageFolder;
      } else if (endpoint === 'user-files') {
        params.menu = 'images';
        if (this.activeImageFolder > 0) params.folder_id = this.activeImageFolder;
      } else {
        params.menu = 'images';
        params.search = '';
      }

      if (!this.isLoggedIn && endpoint === 'user-files') {
        this.showPreloader = false;
        // this.alertError('', 'Cannot get user files, login required.', 3000, 'TopCenterNotif', true);
        // disable getting temporary files
        Api.getTemporaryFile(this.tmpUserId)
          .then((response) => {
            const data = response.data ? response.data : response;
            const fetchedImages = data.results;

            // sometimes stock return same image twice
            for (let i = 0; i < fetchedImages.length; i += 1) {
              const newImage = fetchedImages[i];
              const isExist = this.images.findIndex(img => img.id === newImage.id) > -1;

              if (!isExist) {
                this.images.push(newImage);
              }
            }

            this.resetPages();
            this.$nextTick(() => {
              this.addScrollWatch();
            });
            this.showPreloader = false;
            // this.totalPages = 0;
          })
          .catch((error) => {
            console.error('error', error);
            this.showPreloader = false;
          });
      } else if (endpoint === 'url') {
        const urlParams = this.keyword;

        if (!this.isUrlTabFirstLoad) {
          this.showPreloader = true;

          // Check if not empty and URL valid
          if (isEmpty(urlParams.url)) {
            this.resetPages(true);
            this.alertError('', 'Please enter a URL to get images from', 5000, 'TopCenterNotif');
            return;
          }
          if (!isURL(urlParams.url)) {
            this.resetPages(true);
            this.alertError(
              '',
              'Please enter a valid URL (include http:// or https://) to get images from',
              5000,
              'TopCenterNotif',
            );
            return;
          }

          Api.scrapeImages(urlParams)
            .then((response) => {
              const data = response.data ? response.data : response;
              const fetchedImages = data.images;

              if (fetchedImages.length) {
                for (let i = 0; i < fetchedImages.length; i += 1) {
                  const isExist = this.images.findIndex(img => img.urls.full === fetchedImages[i]) > -1;

                  if (!isExist) {
                    this.images.push({
                      id: `url_${i + 1}`,
                      urls: {
                        full: fetchedImages[i],
                        raw: fetchedImages[i],
                        regular: fetchedImages[i],
                        small: fetchedImages[i],
                        thumb: fetchedImages[i],
                      },
                    });
                  }
                }

                this.$nextTick(() => {
                  this.addScrollWatch();
                });
              } else {
                this.alertWarn(
                  '',
                  "No images found in URL or the link couldn't be scrapped",
                  5000,
                  'TopCenterNotif',
                );
              }

              this.showPreloader = false;
            })
            .catch((error) => {
              console.error('error', error);
              this.resetPages(true);
            });
        } else {
          this.resetPages(true);
          this.isUrlTabFirstLoad = false;
        }
      } else {
        const apiVersion = (endpoint === 'user-files' || endpoint === 'team-files') ? 'v2' : this.getStockEndpointVersion;
        CollectionsApi.getItems(endpoint, params, apiVersion)
          .then((response) => {
            const getEndpoint = endpoint === 'team-files' ? 'user-files' : endpoint;
            if (getEndpoint !== this.currentTab) {
              // make sure the request is still on active tab
              this.showPreloader = false;
              return;
            }

            const data = response.data ? response.data : response;
            let fetchedImages = data.results;

            if (endpoint === 'user-files' || endpoint === 'team-files') {
              const { files, folders } = response.data.results;

              fetchedImages = files;

              if (this.teamId) {
                folders.forEach((item) => {
                  const { name: replaceByKey, ...rest } = item;
                  const newObj = {
                    folder_name: replaceByKey,
                    ...rest,
                  };

                  // Avoid duplicate folders
                  let isExist = false;
                  if (this.folders.length) {
                    isExist = this.folders.find(folder => folder.id === newObj.id);
                  }
                  if (!isExist) this.folders.push(newObj);
                });
              } else if (!this.folders.length && !this.teamId) {
                this.folders = folders;
              }
            }

            // has search_id
            if (typeof data.search_id !== 'undefined') {
              // update store for license usage
              this.setShutterstockSearchId(data.search_id);
            }

            // sometimes stock return same image twice
            for (let i = 0; i < fetchedImages.length; i += 1) {
              const newImage = fetchedImages[i];
              const isExist = this.images.findIndex(img => img.id === newImage.id) > -1;

              if (!isExist) {
                this.images.push(newImage);
              }
            }

            if (this.currentTab === 'stocks') {
              this.totalPages = data.total_pages;
            } else {
              this.resetPages();
            }
            this.$nextTick(() => {
              this.addScrollWatch();
            });
          })
          .catch((error) => {
            console.error('error', error);
          })
          .finally(() => {
            this.$nextTick(() => {
              // if still getting uploads, don't hide the loader
              if (
                (params.folder_id && params.folder_id === this.activeImageFolder) ||
                (isEmpty(params.folder_id) && !this.activeImageFolder)
              ) {
                this.showPreloader = false;
              }
            });
          });
      }
    },
    getUrl(image) {
      return image.urls ? image.urls.regular : image.file;
    },
    getThumbUrl(image) {
      return image.urls ? image.urls.thumb : image.preview_url;
    },
    changeTab(tab) {
      if (this.currentTab !== tab) {
        this.$emit('change-tab', tab);
        if (this.$refs.search) this.$refs.search.clearSearch();
        this.currentTab = tab;
        this.currentPage = 0;
        this.activeImageFolder = 0;
        this.totalPages = null;
        this.images = [];
        this.tmpSelectedImageId = '';
        this.$nextTick(() => {
          this.addScrollWatch(true);
        });
      }
    },
    selectImage(id) {
      console.log('selectImage', id);
      this.selectedPreviewSampleIndex = -1;
      this.tmpSelectedImageId = id;
      if (this.isMobileVersion) {
        this.confirmImage();
      }
    },
    confirmImage() {
      let selectedImage = {};

      if (this.selectedImageUrl) {
        const selectedPath = this.previewAssets[this.selectedPreviewSampleIndex];

        let hasRemovedBg = false;
        if (PreviewAssets.withoutBg.indexOf(selectedPath) >= 0) {
          hasRemovedBg = true;
        }

        selectedImage = {
          file: this.selectedImageUrl,
          preview_url: this.selectedImageUrl,
          id: '',
          has_removed_bg: hasRemovedBg,
        };
      } else {
        // user-files tab images will most likely lead to here
        const index = this.images.findIndex(image => image.id === this.tmpSelectedImageId);
        selectedImage = this.images[index];

        if (this.isInTeamWorkspace && this.currentTab === 'user-files') {
          this.setIsTeamFileUpload(true);
        } else {
          this.setIsTeamFileUpload(false);
        }
      }

      // console.log('selectedImage', selectedImage);
      this.setIsModalVisible(false);
      this.$emit('selected-image-object', selectedImage);
      // this.setShowRemoveBgOption(true);
    },
    folderClickEvent(id) {
      if (this.isMovingMultiSelectedFiles) {
        this.isMovingToFolder = id;
      } else {
        this.isMultiSelecting = false;
        this.isMovingMultiSelectedFiles = false;
        this.multiSelectedFiles.length = 0;
        this.isMovingToFolder = 0;
        this.changeActiveFolder(id);
        this.setActiveFolder(id);
      }
    },
    changeActiveFolder(id) {
      this.activeImageFolder = id;
    },
    resetActiveFolder() {
      if (this.teamId) {
        const currentFolder = this.folders.find(item => item.id === this.activeImageFolder);
        this.activeImageFolder = currentFolder.parent_id || 0;
        this.setActiveFolder(currentFolder.parent_id);
      } else {
        this.activeImageFolder = 0;
        this.setActiveFolder(null);
      }
      this.multiSelectedFiles.length = 0;
      this.isMultiSelecting = false;
    },
    clearSearch() {
      this.keyword = '';
    },
    search(keyword) {
      this.keyword = keyword;
      this.getImages(true);
    },
    dragEnter() {
      if (!this.isDraggingUserFile) this.isDragEnter = true;
    },
    dragLeave() {
      this.isDragEnter = false;
    },
    drop(file) {
      if (this.currentTab === 'user-files' && this.teamId > 0) {
        this.isDragEnter = false;
        this.alertWarn(
          '',
          'Please upload team files at dashboard',
          3000,
          'TopCenterNotif',
        );
        return;
      }

      if (this.isLiteVersion) {
        // for lite version, only allow images
        console.log('this.validExtension', this.validExtensions, file.type);
        if (!this.validExtensions.includes(file.type)) {
          const message = 'Upload failed. Only file types png, jpg and jpeg are accepted.';
          this.isDragEnter = false;
          this.alertError('', message, 3000, 'TopCenterNotif', true);
          return;
        }
      }
      this.files = [file];
      this.isDragEnter = false;
      if (this.isLiteMode && this.currentTab !== 'user-files') {
        this.showPreloader = true;
      }
      this.currentTab = 'user-files'; // change tab after dropped
      this.$nextTick(() => {
        this.isUploadingFile = true;
        this.doCheckFiles();
      });
    },
    removeFileId(id) {
      const ids = Array.isArray(id) ? id.split('|') : [id];

      for (let i = 0; i < ids.length; i += 1) {
        // need to use == cause comparing integer and string
        // eslint-disable-next-line
        const index = this.images.findIndex(image => image.id == ids[i]);
        if (index >= 0) this.images.splice(index, 1);
      }
    },
    createFolder() {
      this.isCreatingFolder = true;
      const folderName = 'New folder';
      UserApi.createUserFolder(folderName)
        .then((response) => {
          if (response.data.success) {
            this.alertSuccess('Hey there!', response.data.message, 5000);
            this.folders.unshift(response.data.results);
          } else {
            this.alertError('Hey there!', response.data.message, 5000);
          }
          // this.setUserFolders(response.data.results);
        })
        .catch((error) => {
          console.log(error);
          if (error && error.response) this.alertError('Hey there!', error.response.data.message, 5000);
        })
        .then(() => {
          this.isCreatingFolder = false;
        });
    },
    uploadingFiles(type, files) {
      this.isUploadingFile = true;

      if (type === 'drop') {
        this.doDropFile(files);
      } else {
        this.doSelectFile();
      }
    },
    dropFilesToFolder(data) {
      const { fileId, folderId } = data;
      this.isDropping = false;
      if (!fileId || !folderId) return;
      this.moveFile(folderId, fileId);
    },
    dropFilesToMainFolder(data) {
      const { id } = data;
      this.isMainFolderHovered = false;
      this.isDropping = false;
      if (!id) return;
      const folderId = 0;
      this.moveFile(folderId, id);
    },
    moveFile(folderId, fileId) {
      const fileIds = Array.isArray(fileId) ? fileId.slice(0) : [fileId];

      for (let i = 0; i < fileIds.length; i += 1) {
        const id = fileIds[i];
        const index = this.images.findIndex(image => image.id === id);
        if (index >= 0) this.images[index].folder_id = folderId;
      }

      UserApi.moveMultipleFilesToFolder(folderId, fileIds)
        .then((response) => {
          if (response.data.success) {
            this.$notify({
              group: 'BottomRightNotif',
              title: 'Hey there!',
              type: 'success',
              text: response.data.message,
              duration: 5000,
            });
          } else {
            this.alertError(response.data.message || 'Moving file failed');
          }
        })
        .catch(() => {
          this.alertError('Moving file failed');
        })
        .then(() => {
          this.isCreatingFolder = false;
        });

      if (this.isMovingMultiSelectedFiles) {
        this.multiSelectedFiles.length = 0;
        this.isMovingMultiSelectedFiles = false;
        this.isMovingToFolder = 0;
        this.isMultiSelecting = false;
      }
    },
    showFileUpload() {
      if (this.currentTab === 'user-files' && this.$refs.fileUpload) this.$refs.fileUpload.click();
    },
    getSelectedImageUrl() {
      if (this.selectedPreviewSampleIndex === -1) {
        this.selectedImageUrl = '';
        return;
      }

      const path = this.previewAssets[this.selectedPreviewSampleIndex];
      if (path) this.selectedImageUrl = this.getImageUrlFromSample(path);
    },
    getImageUrlFromSample(path) {
      return `${process.env.VUE_APP_CDN_ASSETS}/smart-create/${path}`;
    },
    setPreviewIndex(index) {
      this.selectedPreviewSampleIndex = index;
    },
    moveFileMobileEvent() {
      console.log(
        'this.mov',
        this.isMovingMultiSelectedFiles,
        this.isMovingToFolder,
        this.multiSelectedFiles,
      );
      if (this.isMovingMultiSelectedFiles) {
        this.moveFile(this.isMovingToFolder, this.multiSelectedFiles);
      } else {
        this.isMovingMultiSelectedFiles = true;
      }
    },
    moveFileOutMobileEvent() {
      this.isMovingMultiSelectedFiles = true;
      this.isMovingToFolder = 0;
      this.moveFile(this.isMovingToFolder, this.multiSelectedFiles);
    },
    deleteFile() {
      this.promptConfirmDeleteUserFile({
        itemId: this.multiSelectedFiles.join('|'),
        isFolder: false,
        isTemporaryFile: !this.isLoggedIn,
      });
      this.multiSelectedFiles.length = 0;
    },
    cancelEvent() {
      // if user is moving multi selected file, cancel that one
      if (this.isMovingMultiSelectedFiles) {
        this.isMovingMultiSelectedFiles = false;
      } else {
        this.isMultiSelecting = false;
        this.multiSelectedFiles.length = 0;
        this.multiSelectedFiles.pop(); // to trigger computed
      }
    },
    addMultiSelectedFiles(id) {
      const index = this.multiSelectedFiles.indexOf(id);
      if (index === -1) this.multiSelectedFiles.push(id);
    },
    removeMultiSelectedFiles(id) {
      const index = this.multiSelectedFiles.indexOf(id);
      if (index > -1) this.multiSelectedFiles.splice(index, 1);
    },
    onClickOutsideFolderOptions() {
      if (this.showFolderOption) this.showFolderOption = false;
    },
    setFolderOption(value) {
      this.showFolderOption = value;
    },
    onClickOutsideFileUploadOptions() {
      if (this.showFileUploadOption) {
        this.mobileHideFileUpload();
      }
    },
    confirmFolderName(event) {
      console.log('confirm', event.target.value);
      const oldName = this.activeImageFolderName;
      if (!event.target.value || event.target.value === oldName) return;
      const params = {
        folder_name: event.target.value,
      };

      // eslint-disable-next-line
      const index = this.folders.findIndex(folder => folder.id == this.activeImageFolder);
      if (index > -1) this.folders[index].folder_name = event.target.value;

      UserApi.updateUserFolder(this.activeImageFolder, params)
        .then((response) => {
          if (response.data.success) {
            this.$notify({
              group: 'BottomRightNotif',
              title: 'Hey there!',
              type: 'success',
              text: response.data.message,
              duration: 5000,
            });
          } else {
            this.alertError(response.data.message || 'Updating folder name failed');
            if (index > -1) this.folders[index].folder_name = oldName;
          }
        })
        .catch((error) => {
          console.log(error);
          this.alertError();
          if (index > -1) this.folders[index].folder_name = oldName;
        });

      this.isFolderNameReadOnly = true;
    },
    editFolderName() {
      this.isFolderNameReadOnly = false;

      setTimeout(() => {
        this.$refs.folderName.focus();
      }, 100);
    },
    deleteFolder() {
      this.isDeletingFolder = true;
      this.promptConfirmDeleteUserFile({
        itemId: this.activeImageFolder,
        isFolder: this.isDeletingFolder,
        isTemporaryFile: false,
      });
    },
    triggerFileUpload() {
      this.$refs.mobileFileUpload.click();
    },
    mobileHideFileUpload() {
      this.showMainAreaOverlay = false;
      this.showFileUploadOption = false;
    },
    mobileShowFileUpload() {
      this.showMainAreaOverlay = true;
      this.showFileUploadOption = true;
    },
    dropboxUploadSuccess() {
      this.getImages();
      this.mobileHideFileUpload();
    },
    resetPages(hidePreloader = false) {
      this.currentPage = 0;
      this.totalPages = 0;
      if (hidePreloader) {
        this.showPreloader = false;
      }
    },
    onClickLiteUpload() {
      this.changeTab('user-files');
      setTimeout(() => {
        this.$refs.fileUpload.click();
      }, 500);
    },
    modalClickHandler() {
      if (this.isDragEnter) {
        this.dragLeave();
      }
    },
    onSampleClick(index) {
      this.setPreviewIndex(index);
      console.log('onSampleClick', index, this.isMobileVersion);
      this.$nextTick(() => {
        if (this.isMobileVersion) {
          this.confirmImage();
        }
      });
    },
  },
  beforeMount() {
    this.previewAssets = PreviewAssets.withoutBg.concat(PreviewAssets.withBg);
    this.getSelectedImageUrl();
  },
  mounted() {
    this.currentTab = this.preSelectedTab;
    this.isImageLogoOverlay = true;
    this.addScrollWatch();

    this.$root.$on('delete-user-file', (id) => {
      console.log('is deleteing', this.isDeletingFolder, id);
      if (this.isDeletingFolder) {
        // eslint-disable-next-line
        const index = this.folders.findIndex(folder => folder.id == this.activeImageFolder);
        this.activeImageFolder = 0;
        this.folders.splice(index, 1);
        this.isDeletingFolder = false;
      } else {
        this.removeFileId(id);
        this.tmpSelectedImageId = '';
      }
      this.cancelEvent();
    });
  },
  watch: {
    selectedPreviewSampleIndex() {
      this.getSelectedImageUrl();
    },
    currentTab(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.isUrlTabFirstLoad = true;
      }
    },
    activeImageFolder: {
      handler(newVal, oldVal) {
        if (!isEmpty(oldVal) && newVal !== oldVal) {
          this.showPreloader = true;
          this.getImages(true);
        }
      },
      immediate: true,
    },
    isDoneUploading(val) {
      if (val) {
        this.isUploadingFile = false;
        this.getImages(true);
      }
    },
  },
};
</script>

<style lang="scss">
.replace-images--overlay {
  .canvas-modal__content {
    @include mobile() {
      bottom: 0;
      margin-top: auto;
    }
  }

  .canvas-modal__main {
    max-height: 780px;

    .title {
      font-size: 1.5em;
      font-weight: 600;
    }
    .content {
      padding: 0;
    }
  }

  .content--top {
    padding: 0 20px;
    justify-content: flex-start;
    background: var(--lightgrey100-black700);
  }

  .content--bottom {
    justify-content: flex-end;
    padding: 8px 30px !important;

    .use-image__button {
      &:disabled {
        color: var(--lightgrey700-darkgrey200);
        background: var(--lightgrey400-darkgrey600);
      }
    }

    @include mobile() {
      .btn {
        width: 100%;
      }
    }
  }

  .search-container {
    padding: 24px 30px 10px;
    height: auto;

    @include mobile() {
      margin-top: 10px;
    }
  }

  .tools-bar {
    padding: 25px 35px 10px 30px;
    z-index: 99;
    height: auto;
    text-align: right;

    @include mobile() {
      padding: 10px 15px;
    }

    .search-container {
      width: 100%;
      display: block;
      padding: 0;

      .form-group {
        height: 36px;
        .row,
        .tab-search {
          height: 100%;
        }
      }
    }
  }

  .file-upload-container {
    width: 100%;
    display: inline-block;
    vertical-align: top;

    @include mobile() {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .upload-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    z-index: 2;

    .dropbox-selector {
      background-color: transparent;
      border: 0;
    }

    @include mobile() {
      display: inline-flex;
      width: auto;
    }

    &.has-create-folder {
      justify-content: space-between;
    }

    .btn {
      margin: 0 7px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .btn-create-folder {
    width: 48px;
    height: 48px;
    color: var(--blue700-white);
    transition: color 0.25s ease-in-out;
    margin-right: auto;

    @include mobile() {
      width: auto;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    &:not(.is-loading):hover {
      color: var(--blue600-green600);
    }

    &.is-loading {
      color: $grey;
    }

    .icon {
      font-size: 2.5em;

      @include mobile() {
        font-size: 1.5em;
      }
    }

    .text {
      display: none;
      font-size: 0.875em;
      margin-left: 5px;
      font-weight: 500;

      @include mobile() {
        display: inline-block;
      }
    }
  }

  .btn-local {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 200px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: auto;
    overflow: initial; // so tooltip not hidden
    background: var(--blue700-darkgrey900);
    color: $white;

    @include mobile() {
      display: none;
    }

    &:not(.is-dark) {
      &:hover,
      &:active {
        background: $light;
        color: $blue700;

        .tooltip-wrapper .icon {
          transition: color 0.2s ease-in-out;
          color: $blue700;
        }
      }
    }

    &.is-dark {
      &:hover,
      &:active {
        background: $darkGrey5;
        color: $light;

        .icon-plus {
          transition: color 0.2s ease-in-out;
          color: $green600;
        }
      }
    }

    .icon {
      font-size: 1rem;
    }

    input[type='file'] {
      display: none;
    }

    .label {
      display: block;
      margin-left: 5px;
      margin-right: 5px;
    }

    .tooltip-wrapper {
      position: relative;
      z-index: 2;
      margin-left: auto;

      &:hover {
        .tooltip {
          opacity: 1;
          display: block;
        }
      }

      .icon {
        color: $white;
      }

      .tooltip {
        display: none;
        opacity: 0;
        transition: 0.3s;
        position: absolute;
        background-color: $lightGreen;
        color: $white;
        top: 22px;
        left: 0;
        padding: 15px;
        width: 170px;
        margin-left: -113px;
        text-align: center;
        border-radius: $componentBorderRadius;

        strong {
          font-weight: 500;
        }

        .file-format {
          font-weight: 500;
          margin-bottom: 10px;
        }
      }
    }
  }

  .btn-google-drive,
  .btn-dropbox {
    padding: 0;

    @include mobile() {
      display: none;
    }
  }

  .btn-dropbox {
    &:hover {
      .dropbox-selector {
        background-color: var(--blue700-black800) !important;
        border-color: var(--blue700-black800) !important;
      }

      .icon-dropbox {
        color: $white;
      }
    }

    .dropbox-selector {
      height: 34px;
      background-color: var(--white-darkgrey700) !important;
      border: 1px solid var(--blue700-white);
    }

    .select-files {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .icon-dropbox {
      color: var(--blue700-white);
      padding: 0;
      height: auto;
      width: auto;
      background: transparent;
    }
  }

  .content {
    height: calc(90vh - 250px);
    overflow: hidden;
  }

  .canvas-modal {
    text-align: left;
  }

  .tab {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__head {
      width: 100%;
      height: 56px;
      padding: 0 24px;
      border: 1px solid #e1e4e8;
      box-shadow: 0 3px 4px rgba(10, 31, 68, 0.1), 0 0 1px rgba(10, 31, 68, 0.08);
      border-radius: 4px;
    }

    &__body {
      height: 100%;
      padding: 20px 20px 10px;
      overflow: hidden;
      position: relative;
    }

    &__footer {
      padding: 10px 20px;
      display: flex;
      justify-content: flex-end;
      box-shadow: 0px -6px 26px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
      margin-top: 20px;
    }
  }
}

.smart-create-image__modal {
  &.is-dark {
    .content--top {
      box-shadow: 0px 18px 18px rgb(0 0 0 / 12%), 0px 0px 1px rgb(0 0 0 / 10%);
    }

    .content--bottom {
     box-shadow: 0px -10px 20px rgb(0 0 0 / 50%), 0px 0px 1px rgb(0 0 0 / 10%);
    }
  }

  .canvas-modal__header {
    background: var(--blue700-darkgrey900) !important;
    color: $white;
  }

  .canvas-modal__content {
    background: var(--white-darkgrey700);

    .canvas-modal__closebtn {
      color: $white;
    }

    .content {
      background: var(--white-darkgrey700);
    }
  }

  * {
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    ::-webkit-scrollbar-track {
      background-color: rgba($darkGrey600, 0);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--lightgrey500-black800);
      transition: background 0.25s ease-in-out;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--lightgrey500-black800);
    }
  }
}

.replace-images--list {
  width: 100%;
  height: calc(100% - 50px);
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 0 20px 40px;

  @include mobile() {
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 200px;
  }

  .preloader {
    height: 128px;
  }

  .is-selected {
    img {
      border: 2px solid var(--blue700-green700);
    }
  }
}

.drop-main-folder {
  width: 120px;
  position: absolute;
  top: 80px;
  left: 10px;
  z-index: 100;

  @include mobile() {
    top: 90px;
    left: 0;
  }
}

.btn-reset-images {
  color: var(--black-white) !important;
  display: flex;
  padding: 5px;
  margin: 0 20px 10px 20px;
  font-size: 0.875em;
  align-items: center;

  .icon {
    display: inline-block;
    transition: transform 0.25s ease-in-out;
  }

  &:hover,
  &:focus {
    background: transparent !important;

    .icon {
      transform: translateX(-5px);
    }
  }
}

.replace-images__text {
  width: 100%;
  text-align: center;
}

.drop-zone-container,
.empty-list {
  height: 300px;
  width: 620px;
  position: absolute;
  border-radius: $componentBorderRadius;

  @include mobile() {
    width: calc(100% - 40px);
    height: 250px;
  }
}

.drop-zone-container {
  pointer-events: none;
  z-index: 3;
  cursor: pointer;
  top: 52px;
  left: 0;
  width: 100%;
  transform: none;
  height: calc(100% - 110px);

  > div {
    pointer-events: auto;
  }
}

.empty-team-uploads__list {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .text {
    color: var(--black-white);
  }
}

.empty-list {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  transition: background 0.25s ease-in-out;

  &:not(.is-url).is-dark {
    &:hover {
      box-shadow: 7px 9px 7px 0 rgb(0 0 0 / 15%);
    }
  }

  &.inside-folder {
    top: calc(50% + 50px);
  }

  &.is-url {
    width: 75%;

    @include mobile() {
      width: 100%;
      left: 0;
      height: 100%;
    }
  }

  &:not(.is-url) {
    cursor: pointer;
    border: 2px dashed var(--lightgrey400-darkgrey100);

    &:hover {
      background: var(--lightgrey-lightgrey6);
    }
  }

  @include mobile() {
    position: absolute;
    top: 0;
    left: 20px;
    transform: none;
    padding: 20px;
  }

  .icon {
    &:not(.icon-cancel) {
      font-size: 4em;
      margin-top: -20px;
      color: var(--darkgrey500-lightgrey300);
    }

    &.icon-download1 {
      font-size: 2.75em;
    }
  }

  .title {
    font-size: 1.25em !important;
    margin: 10px 0 5px !important;
  }

  .title,
  .text {
    color: var(--black-white);
  }
}

.template-masonry {
  padding: 0 10px;
  margin-top: 10px;
  position: relative;
  z-index: 2;
  width: 100%;
}

.replace-images__sample-image {
  width: calc(100% / 8 - 24px);
  position: relative;
  border-radius: $componentBorderRadius;
  overflow: hidden;
  background: var(--lightgrey300-darkgrey600);
  margin: 0 12px 24px 12px;
  cursor: pointer;

  .upload-files__mobile-smart-create ~ & {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  @include mobile() {
    width: calc(100% / 3 - 24px);
  }

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.tools-bar__mobile {
  display: none;
  margin: 15px 0;

  &.full-width {
    width: 100%;
  }

  button {
    font-size: 0.875em;
    font-weight: 500;
    display: inline-block;
  }

  @include mobile() {
    display: inline-flex;
  }

  .btn-move-file {
    color: $green;
  }

  .btn-move-file-out,
  .btn-delete-file {
    color: $red;
  }

  .btn-cancel-multiselect {
    margin-left: auto;
  }
}

.btn-upload-mobile {
  background: $blue;
  color: $light;
  width: 54px;
  height: 54px;
  display: none;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 13px 13px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
  right: 15px;
  bottom: 118px;
  position: fixed;
  z-index: 10;
  font-size: 1.25em;
  border-radius: 50%;

  @include mobile() {
    display: flex;
  }
}

.folder-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px 10px;
  color: var(--black-white);

  @include mobile() {
    padding: 0;
  }

  &.is-team-upload {
    padding-top: 50px;
  }

  .btn-edit-folder {
    font-size: 1.25em;
    color: var(--black-white);
  }

  .folder-title__name {
    font-weight: 700;
    font-size: 1.25em;

    @include mobile() {
      font-size: 1em;
    }
  }
}

.folder-options,
.upload-files__mobile {
  position: absolute;
  bottom: 15px;
  left: 15px;
  width: calc(100% - 30px);
  background: var(--white-darkgrey600);
  border-radius: $componentBorderRadius;
  box-shadow: 0px 13px 13px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
  z-index: 11;
  padding: 10px;

  button {
    border-bottom: 1px solid var(--lightgrey400-darkgrey200);

    &:last-child {
      border-bottom: 0;
    }
  }

  .text {
    font-size: 0.875em;
  }

  .icon {
    font-size: 1.25em;
  }
}

.folder-options {
  .folder-options__name {
    width: 100%;
    border: 0;
    border-bottom: 1px solid var(--white-darkgrey900);
    background: transparent;
    padding: 10px 0 12px;
    font-size: 1.125em;
    font-weight: 600;
    color: var(--darkgrey400-lightgrey400);
    outline: 0;

    &:not(:read-only):focus {
      border-bottom-color: var(--blue700-green700);
    }
  }

  .btn-rename-folder,
  .btn-delete-folder {
    padding: 12px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .btn-rename-folder {
    color: var(--black-white);
  }

  .btn-delete-folder {
    color: $red600;
  }
}

.upload-files__mobile {
  text-align: left;

  .upload-files__name {
    width: 100%;
    padding: 10px 0 12px;
    font-size: 1.125em;
    font-weight: 600;
    display: block;
    color: $inputLabel;
  }

  .btn-upload-image-phone,
  .btn-upload-image-dropbox {
    width: 100%;
    padding: 12px 0;
    height: auto;
  }

  .btn-upload-image-phone,
  .btn-upload-image-dropbox .select-files {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .btn-upload-image-phone {
    border-bottom: 1px solid $borderGrey;
    position: relative;
  }

  #mobile-file-upload {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
  }

  .btn-upload-image-dropbox {
    img {
      width: 20px;
    }
  }
}

.user-file-skeleton-preloader {
  cursor: no-drop;
  opacity: 0.7;
  display: inline-block;
  padding: 12px;
  position: relative;
  user-select: none;
  width: calc(100% / 8);

  &.is-dark {
    .preloader__image {
      background: url('~@/assets/images/backgrounds/transparent-dark.jpg');
    }
  }

  .preloader__image {
    background: url('~@/assets/images/backgrounds/transparent.png');
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 80px;
    color: $darkGrey;
    position: absolute;
    top: 12px;
    left: 12px;
    width: calc(100% - 24px);
    height: calc(100% - 24px);
    border-radius: $componentBorderRadius;
    box-shadow: 0 3px 4px rgba(10, 31, 68, 0.12), 0 0 1px rgba(10, 31, 68, 0.1);
    transition: box-shadow 0.25s ease-in-out, background 0.25s ease-in-out, color 0.25s ease-in-out;

    svg {
      width: 50% !important;
      height: auto !important;
    }

    @include mobile() {
      font-size: 60px;
    }
  }

  .preloader__text {
    padding: 7px;
    font-size: 0.75em;
    position: absolute;
    left: 12px;
    bottom: 12px;
    width: calc(100% - 24px);
    border-radius: 0 0 $componentBorderRadius $componentBorderRadius;
    border-top: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: var(--white-darkgrey800);
    color: var(--darkgrey800-white);
    border: 1px solid var(--white-darkgrey800);
  }
}

@include mobile() {
  .slide-mobile-upload-enter-to,
  .slide-mobile-upload-leave {
    transform: translateY(0);
  }

  .slide-mobile-upload-enter,
  .slide-mobile-upload-leave-to {
    transform: translateY(100%);
  }

  .slide-mobile-upload-enter-active {
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  .slide-mobile-upload-leave-active {
    transition-duration: 0.3s;
    transition-timing-function: ease-in;
  }
}

.lite-version-upload-button {
  margin-left: auto;
}

.upload-files__mobile-smart-create {
  position: relative;
  display: inline-block;
  width: calc(100% / 3);
  padding: 12px;

  .btn-upload-image-phone {
    background: $darkGrey900;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.5em;
    }

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }

  .upload-files__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
</style>
